import * as React from "react";

interface Props {
  color?: string
}

const Close = (props:Props) => (
  <svg version="1.1" id="close.svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 43.4 43.4" xmlSpace="preserve">
  <g id="レイヤー_2">
    <rect x="18.7" y="-6" transform="matrix(0.707 -0.7072 0.7072 0.707 -8.996 21.7224)" width="6" height="55.5"/>
  </g>
  <path d="M39.2,0l4.2,4.2L4.2,43.4L0,39.2L39.2,0z"/>
  </svg>
);

const Plus = (props:Props) => (
  <svg version="1.1" id="plus.svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 30 30" xmlSpace="preserve">
    <path  d="M12,0h6v30h-6V0z"/>
    <rect y="12" width="30" height="6"/>
  </svg>
);

const Share = (props:Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="49.97" viewBox="0 0 50 49.97">
    <path d="M874.654,1638.99a11.662,11.662,0,0,1-8.249-19.9l10.6-10.6a11.643,11.643,0,0,1,14.54-1.57,11.073,11.073,0,0,1,1.958,1.57,3.3,3.3,0,0,1,0,4.68,3.413,3.413,0,0,1-4.683,0,5,5,0,0,0-7.066,0l-10.639,10.63a5,5,0,1,0,7.073,7.07h0l6.291-6.3a0.828,0.828,0,0,1,.908-0.18,13.928,13.928,0,0,0,5.333,1.03h0.333a0.837,0.837,0,0,1,.838.83,0.853,0.853,0,0,1-.246.6l-8.749,8.74A11.578,11.578,0,0,1,874.654,1638.99Zm16.114-16.07h-0.634a10.325,10.325,0,0,1-1.241-.11c-0.341-.06-0.675-0.13-1.008-0.21l-0.492-.14a5.479,5.479,0,0,1-.533-0.17c-0.175-.07-0.358-0.13-0.533-0.2s-0.35-.14-0.525-0.22a11.674,11.674,0,0,1-3.333-2.34,3.312,3.312,0,0,1,0-4.69,3.413,3.413,0,0,1,4.683,0,5.016,5.016,0,0,0,7.065,0l2.733-2.72,0.067-.07,7.815-7.81a5,5,0,1,0-7.074-7.07l-6.273,6.26a0.833,0.833,0,0,1-.909.19,13.893,13.893,0,0,0-5.324-1.04h-0.308a0.85,0.85,0,0,1-.783-0.51,0.838,0.838,0,0,1,.174-0.92l8.741-8.73a11.663,11.663,0,1,1,16.5,16.49l-10.607,10.61a11.635,11.635,0,0,1-5.407,3.06h-0.092l-0.483.1-0.266.05-0.576.08h-0.283c-0.216,0-.408.05-0.591,0.05Z" transform="translate(-863 -1589.03)"/>
  </svg>
);

const Check = (props:Props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 31.1 23.8" xmlSpace="preserve">
    <path style={{fill: props.color}} d="M0.1,12.5l4.2-4.3l11.4,11.4l-4.2,4.2L0.1,12.5z"/>
    <polygon style={{fill: props.color}} points="7.2,19.6 26.9,0 31.1,4.2 11.6,23.8 "/>
  </svg>
)

const Pin = (props:Props)=>(
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 90 90" xmlSpace="preserve">
    <path d="M63,58H27c-1,0-2-0.5-2.5-1.4s-0.6-2-0.2-2.9L32,37.3v-7.9l-2.9-10.6c-0.2-0.9-0.1-1.9,0.5-2.6c0.6-0.7,1.4-1.2,2.4-1.2h26
      c0.9,0,1.8,0.4,2.4,1.2c0.6,0.7,0.8,1.7,0.5,2.6L58,29.4v7.9l7.7,16.4c0.4,0.9,0.4,2-0.2,2.9C65,57.5,64,58,63,58z M31.7,52h26.5
      l-6-12.7C52.1,38.9,52,38.4,52,38v-9c0-0.3,0-0.5,0.1-0.8l2-7.2H35.9l2,7.2C38,28.5,38,28.7,38,29v9c0,0.4-0.1,0.9-0.3,1.3L31.7,52z
      "/>
    <path d="M45,78c-1.7,0-3-1.3-3-3V56c0-1.7,1.3-3.1,2.9-3.1c0,0,0,0,0,0c1.7,0,3.1,1.4,3.1,3.1v19C48,76.6,46.7,78,45,78
      C45,78,45,78,45,78z"/>
  </svg>
)

const Note = ()=>(
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 90 90" xmlSpace="preserve">
    <path className="el" d="M48.4,21L60,33.4V69H27V21H48.4 M51,15H24c-1.7,0-3,1.3-3,3v54c0,1.7,1.3,3,3,3h39c1.7,0,3-1.3,3-3V31L51,15L51,15z"/>
    <polygon className="el" points="54,54 33,54 33,60 54,60 54,54 "/>
    <polygon className="el" points="54,42 33,42 33,48 54,48 54,42 "/>
    <polygon className="el" points="48,30 33,30 33,36 48,36 48,30 "/>
  </svg>
)

export {Close, Plus, Share, Check, Pin, Note}
