import * as Types from './types';
import Cookies from 'js-cookie';

export default (state = {}, action) => {
  switch (action.type) {
    case Types.post.fulfilled.toString():
      return {
        ...state,
        isLogin: 2
      };
    case Types.post.rejected.toString():
      Cookies.remove('token');
      return {
        ...state,
        isLogin: 1
      };
    case Types.LOGOUT:
      Cookies.remove('token');
      return {
        ...state,
        isLogin: 1
      };
    case Types.SET_TOKEN:
      Cookies.set('token', action.payload, { expires: 7 });
      return state;
    default:
      return state;
  }
};
