import * as Types from "./types";
import * as ProjectTypes from "../project/types";
import Cookies from "js-cookie";

export default (state = {}, action) => {
  switch (action.type) {
    case Types.get_users.fulfilled.toString():
      return {
        ...state,
        users: action.payload,
      };
    case ProjectTypes.put_project_members.fulfilled.toString():
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
};
