import { createAction } from 'redux-actions';
import Cookies from 'js-cookie';
import req from '../../../utils/req';

import * as types from './types';
import * as projectsTypes from '../projects/types';

const set_token = createAction(types.SET_TOKEN);

const setToken = payload => (dispatch, getState) => {
  dispatch(set_token(payload));
};

const logout = createAction(types.LOGOUT);

const post = () => async (dispatch, getState) => {
  dispatch(types.post.pending());

  const res = await req('GET_PROJECTS', {}, Cookies.get('token')).catch(e => {
    dispatch(types.post.rejected(e));
  });

  if (res) {
    dispatch(projectsTypes.get.fulfilled(res.data));
    dispatch(types.post.fulfilled());
    return;
  }
};

export { post, logout, setToken };
