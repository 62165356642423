import React, { Component } from 'react';
import {setup} from 'bem-cn'
import UserType from '../../../types/user'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});
const d = block('dialog')

interface Props {
  user:UserType,
  userId: number,
  postedUserIds: number[],
}
interface State {}

class Cover extends Component<Props, State> {
  public state: State = {
  }

  render() {
    return (
      <div className="dialog__cover">
        <div className={d("cover-inner")}>
          <div className={d("cover-thumb")}>
            <span style={{backgroundImage: `url("${this.props.user.iconUrl}")`}} />
          </div>
          <div className={d("cover-username")}>
            {this.props.user.name}
          </div>
          {this.props.postedUserIds && this.props.postedUserIds.length > 1 &&
            <ul className={d("cover-indicator")}>
              {this.props.postedUserIds.map(id=>(
                <li key={id} className={d("cover-indicator-item", {active: id === this.props.userId})}></li>
              ))}
            </ul>
          }
        </div>
      </div>
    );
  }
}

export default Cover;
