import { connect } from "react-redux";
import * as Actions from "../../states/ducks/util/actions";
import * as ProjectActions from "../../states/ducks/project/actions";
import EditUser from "../pages/edit-user";

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return { users: state.util.users };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(Actions.get_users()),
    putUsers: (ids) => dispatch(ProjectActions.send_project_members(ids)),
  };
};

const EditUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);

export default EditUserContainer;
