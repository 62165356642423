import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Share,Close} from '../common/icons';
import {date} from '../../../utils/utils'
import './project-item.scss'
import {setup} from 'bem-cn';
import ProjectSummary from '../../../types/project-summary'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});
const b = block('project-item')

interface Props extends ProjectSummary {
  userId: number,
  remove(object): void
  edit(): void
}

interface State {
}

class ProjectItem extends Component<Props, State> {
  public state: State = {
  }

  constructor(props:Props) {
    super(props)
    this.remove = this.remove.bind(this)
  }

  remove(e){
    this.props.remove({projectHash: this.props.url, projectId: this.props.id, shared: this.props.shared});
  }

  render() {
    return (
      <div className={b({shared: this.props.shared})}>
        <div className={b("remove")}>
          <button onClick={this.props.edit}>
            <img src="/images/edit-project.svg" alt=""/>
          </button>

          <button onClick={this.remove}>
            <Close/>
          </button>
        </div>
        <Link className={b("inner")} to={`/projects/${this.props.url}/${this.props.userId}`}>
          <h2 className={b("title")}>{this.props.name}{ this.props.shared && <span><Share/></span>}</h2>
          <span className={b("summary")}>{this.props.latestPost}</span>
          <span className={b("date")}>{date(`${this.props.postTime}`)}</span>
        </Link>
      </div>
    );
  }
}

export default ProjectItem;

