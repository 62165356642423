import React, { Component } from 'react';
import {setup} from 'bem-cn';
import './user-icon.scss'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});
const b = block('user-icon')

interface Props {
  image: string,
  size: number,
  style?: object
}

interface State {
}

class UserIcon extends Component<Props, State> {
  public state: State = {
  }

  render() {
    const style = {
      display: 'inline-block',
      backgroundImage: `url('${this.props.image}')`,
      width: `${this.props.size}px`,
      height: `${this.props.size}px`,
      ...this.props.style,
    }

    return (
        <span className={b()} style={style}/>
    );
  }
}
export default UserIcon;
