import { connect } from 'react-redux';
import * as Actions from '../../states/ducks/auth/actions';
import * as ProjectsActions from '../../states/ducks/projects/actions';

import SSO from '../pages/sso';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.auth
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    setToken: token => {
      dispatch(Actions.setToken(token));
      dispatch(Actions.post());
      dispatch(ProjectsActions.getUser());
    }
  };
};

const SSOContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SSO);

export default SSOContainer;
