import React, { Component } from 'react';

interface Props {
  name: string,
  valid: boolean,
  toggleSource(): void,
  right?: boolean
}
interface State {
  id: string,
  checked: boolean
}

class CheckBox extends Component<Props, State> {
  public state: State = {
    id: Math.random().toString(36).slice(2),
    checked: false
  }

  check(e){
    this.props.toggleSource();
  }

  render() {
    return (
      <span className={`checkbox${this.props.right?' layout-right':''}`}>
        <input type="checkbox" name={`check-${this.state.id}`} checked={this.props.valid} id={`check-${this.state.id}`} onChange={this.check.bind(this)} />
        <label htmlFor={`check-${this.state.id}`}>{this.props.children}</label>
      </span>
    );
  }
}

export default CheckBox;
