import * as Types from './types';
import Cookies from 'js-cookie';

export default (state = {}, action) => {
  switch (action.type) {
    case Types.post_login.pending.toString():
      return {
        ...state,
        loading: true,
        error: false,
        message: ''
      };
    case Types.post_login.fulfilled.toString():
      Cookies.set('token', action.payload.user.accessToken, { expires: 7 });
      return {
        ...state,
        loading: false,
        error: false,
        message: ''
      };
    case Types.post_login.rejected.toString():
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload
      };
    default:
      return state;
  }
};
