import React from 'react';
import ReactDOM from 'react-dom';
import './sass/styles.scss';
import 'ress/dist/ress.min.css';
import 'ionicons/dist/css/ionicons.css';
import 'font-awesome/css/font-awesome.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

window.onload = ()=>{
  document.getElementById('react-app').removeAttribute('class');
  ReactDOM.render(<App />, document.getElementById('react-app'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
