import axios from 'axios';
import Config from '../states/config';

const __parse = (endpoint, params) => {
  console.log(params);
  return (Config.API.BASE + Config.API[endpoint].url).replace(
    /\{(.+?)\}/g,
    (match, str, ind, self) => {
      return params[str];
    }
  );
};

export default function(
  endpoint,
  options = { params: null, data: null },
  token = null,
  form = false
) {
  let config = {
    method: Config.API[endpoint].method,
    url: __parse(endpoint, options.params),
    withCredentials: true
  };

  if (config.method === 'get') {
    config.params = options.data;
  } else {
    config.data = options.data;
  }

  if (form) {
    config.data = new FormData();
    Object.keys(options.data).forEach(key => {
      config.data.append(key, options.data[key]);
    });
    config.headers = {
      ...config.headers,
      'content-type': `multipart/form-data`
    };
  }

  if (token)
    config.headers = {
      ...config.headers,
      Permission: `Bearer ${token}`
    };

  return axios(config);
}
