import React, { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    input: state.project.input,
    shared: state.project.shared
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
  };
};

interface Props {
  input:Array<any>,
  shared: boolean,
  send(input:string):any,
  queue():any,
  isQueueExist: boolean
}

interface State {
  input:any,
  innerHeight:any,
  isOpen?: boolean,
  placeholder: string
}

class TextInputComponent extends Component<Props, State> {
  public state: State = {
      input: '',
      innerHeight: 0,
      placeholder: 'Type something...'
  }
  text: any;

  send() {
    if (this.state.input){
      this.props.send(this.state.input);
      this.setState({ input: '' });
      this.text.value = '';
    }else if(this.props.isQueueExist){
      this.props.queue();
    };
  }

  sendBySelection(e) {
    this.props.send(e.target.innerText);
    this.setState({ isOpen: false });
  }

  type(e) {
    if (this.state.innerHeight < (window as any).innerHeight) {
      this.setState({ innerHeight: (window as any).innerHeight });
    }
    (window as any).scrollTo(0, this.state.innerHeight);
    this.setState({ input: e.currentTarget.value });
  }

  submit(e) {
    e.preventDefault();
    this.send();
  }

  focus(e) {
    if (this.state.innerHeight < (window as any).innerHeight) {
      this.setState({ innerHeight: (window as any).innerHeight });
    }
    setTimeout(() => {
      (window as any).scrollTo(0, this.state.innerHeight);
    }, 500);
  }

  render() {
    if(this.props.shared)return null;

    return (
      <footer className="footer">
        <div className="footer__inner">
          <div className="footer__center">
            <form action="" onSubmit={this.submit.bind(this)}>
              <input
                type="text"
                placeholder={this.state.placeholder}
                ref={tag => (this.text = tag)}
                onFocus={e => this.focus(e)}
                onChange={e => this.type(e)}
              />
            </form>
          </div>

          <div className="footer__right">
            <button
              className={cn({ disabled: !this.state.input && !this.props.isQueueExist})}
              onClick={this.send.bind(this)}
            >
              SEND
            </button>
          </div>
        </div>
      </footer>
    );
  }
}

const TextInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(TextInputComponent);

export default TextInput;
