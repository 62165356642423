import * as Types from './types';
import { mergeProjects } from '../../../utils/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case Types.get.fulfilled.toString():
      return {
        ...state,
        projects: mergeProjects(action.payload)
      };
    case Types.get.rejected.toString():
      if (action.payload.response !== undefined && action.payload.response.status === 401) {
        alert(action.payload.response.data.msg);
        window.location.href = '/login';
      }
      return state;
    case Types.remove.fulfilled.toString():
      return {
        ...state,
        projects: state.projects.filter(project => project.id !== action.payload)
      };
    case Types.remove_shared.fulfilled.toString():
      return {
        ...state,
        projects: state.projects.filter(project => project.url !== action.payload)
      };
    case Types.update.fulfilled.toString():
      return {
        ...state,
        projects: state.projects.map(project => {
          if (project.id === action.payload.projectId) {
            return {
              ...project,
              name: action.payload.name,
              description: action.payload.description
            };
          } else {
            return project;
          }
        })
      };
    case Types.get_user.fulfilled.toString():
      return {
        ...state,
        user: action.payload.user
      };
    default:
      return state;
  }
};
