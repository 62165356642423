import React, { Component } from 'react';
import './text-input.scss';
import {setup} from 'bem-cn';

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const b = block('text-input')
const m = block('menu')

interface Props {
  placeholder:string,
  defaultValue: string,
  onChange(e:any):void
}

interface State {
  value: string
}

class TextInput extends Component<Props, State> {
  public state: State = {
    value: ''
  }

  remove(e){
    this.setState({value: ''})
  }

  onChange(e){
    this.setState({value: e.target.value})
    this.props.onChange(e)
  }

  componentWillMount(){
    this.setState({
      value: this.props.defaultValue
    })
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      value: nextProps.defaultValue
    })
  }

  render() {
    return (
      <section className={b()}>
        <input className={m("content-input", {empty: this.state.value.length < 1} )} type="text" placeholder={this.props.placeholder} value={this.state.value} onChange={this.onChange.bind(this)}/>
        <button onClick={this.remove.bind(this)} className={b('btn',{active:true})}><img src="/images/remove-input.svg" alt=""/></button>
      </section>
    );
  }
}

export default TextInput;
