import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import cn from 'classnames';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#591e6f'
    },
    secondary: {
      main: '#4dc9ec'
    }
  },
});

interface Props {
  login: any,
  isLogin: number,
  error: boolean,
  loading: boolean,
  message: string
}
interface State {
  mailAddress: string,
  password: string
}

class Login extends Component<Props, State> {
  public state: State = {
    mailAddress: '',
    password: '',
  }

  componentDidMount() {
    //TODO
    //window.document.getElementById('react-app').removeAttribute('class');
    window.scrollTo(0, 0);
  }

  submit(e){
    e.preventDefault();
    this.props.login(this.state);
  }

  change(e){
    switch(e.target.type){
      case 'email':
        this.setState({mailAddress: e.target.value});
      break;
      case 'password':
        this.setState({password: e.target.value});
      break;
    }
  }

  render() {
    return (
      this.props.isLogin >= 2?
      <Redirect to={{pathname: '/'}}/>
      :
      <div className="login">
        <MuiThemeProvider theme={theme}>
          <form onSubmit={this.submit.bind(this)}>
            <div className="login__title">
              <p>TANE</p>
              <span>ver. 0.9</span>
            </div>
            <TextField fullWidth label="Email" type="email" margin="normal" onChange={this.change.bind(this)} />
            <TextField fullWidth label="Password" type="password" margin="normal" onChange={this.change.bind(this)} />
            <p className={cn("login__error", {"login__error--show":this.props.error})}>
              {this.props.message}
            </p>
            <button disabled={this.props.loading || this.state.mailAddress === '' || this.state.password === ''} className={cn("login__submit")}>LOGIN</button>
          </form>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default Login;
