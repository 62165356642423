import req from '../../../utils/req';

import * as types from './types';
import * as authTypes from '../auth/types';
import * as projectsTypes from '../projects/types';

const login = payload => async (dispatch, getState) => {
  dispatch(authTypes.post.pending(payload));
  dispatch(types.post_login.pending());

  const res = await req('POST_LOGIN', { data: payload }).catch(e => {
    let message = e.response ? e.response.statusText : e.message;
    dispatch(authTypes.post.rejected(e));
    dispatch(types.post_login.rejected(message));
  });

  if (res) {
    dispatch(types.post_login.fulfilled(res.data));
    dispatch(projectsTypes.get_user.fulfilled(res.data));
    dispatch(authTypes.post.fulfilled());
  }
};

/**
const sendQueue = payload => (dispatch, getState) => {
  let q = [...payload.queue];
  if (payload.text) q.push(payload.text);
  dispatch(remove_queue());
  dispatch(say(q.join('&')));
  dispatch(getfunc(q.join('&')));
};
 */
export { login };
