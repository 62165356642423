import createAsyncActions from '../../../utils/createAsyncActions';

const PREFIX = 'LOGIN';
const SEPARATOR = '_';

//sync actions
const DONE = PREFIX + SEPARATOR + 'DONE';
const POST = PREFIX + SEPARATOR + 'POST';

//async actions
const post_login = createAsyncActions(POST);

export { post_login, DONE };
