import { connect } from 'react-redux';
import * as Actions from '../../states/ducks/auth/actions';
import * as ProjectsActions from '../../states/ducks/projects/actions';
import Auth from '../components/auth';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.auth,
    user: state.projects.user
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    checkStatus: () => {
      dispatch(Actions.post());
      dispatch(ProjectsActions.getUser());
    }
  };
};

const AuthContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);

export default AuthContainer;
