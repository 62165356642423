import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import TimeLine from "../containers/timeline";
import Menu from "../components/common/menu";
import EditUser from "../containers/edit-user";
import { CopyToClipboard } from "react-copy-to-clipboard";
import cn from "classnames";
import User from "../components/project/user";
import Linkify from "react-linkify";
import ProjectSummary from "../../types/project-summary";
import TimeLineType from "../../types/timeline";
import ProjectDetail from "../../types/project-detail";
import { animateScroll as scroll } from "react-scroll";
import UserType from "../../types/user";
import Loader from "../components/common/loader";
import Config from "../../states/config";
import Header from "../components/project/header";
import OtherMenu from "../components/project/other-menu";

interface Props extends ProjectDetail {
  match: any;
  projectSummary: ProjectSummary | null;
  init(projectHash: string, userId: number): void;
  reset(): void;
  timelines: TimeLineType[];
  usersInfo: any[];
  loginUserInfo: UserType;
  getScrollPos(projectHash: string, usreId: number): number;
  setScrollPos(projectHash: string, usreId: number, pos: number);
  togglePartOfSpeech(name: string): void;
  toggleSource(name: string): void;
  sources: Array<any>;
  partOfSpeech: Array<any>;
}

interface State {
  projectHash: string | null;
  userId: number | null;
  menu: boolean;
  sourceMenu: boolean;
  memberMenu: boolean;
  copied: boolean;
  url: string;
  covers: boolean[];
  swipeIndex: number;
  tlMemo: boolean;
  filtered: boolean;
}

class ProjectComponent extends Component<Props, State> {
  timerId: any;
  options = {
    continuous: false,
    callback: (index, el) => {},
    transitionEnd: (index, el) => {
      this.setState({ swipeIndex: index });
    },
  };

  public state: State = {
    projectHash: null,
    userId: null,
    menu: false,
    sourceMenu: false,
    memberMenu: false,
    copied: false,
    url: "",
    covers: [],
    swipeIndex: 0,
    tlMemo: false,
    filtered: false,
  };

  constructor(props: Props) {
    super(props);
    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.openMemberMenu = this.openMemberMenu.bind(this);
    this.closeMemberMenu = this.closeMemberMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSourceMenu = this.toggleSourceMenu.bind(this);
    this.slide = this.slide.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getLastUpdate = this.getLastUpdate.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
  }

  componentDidMount() {
    this.setState({
      menu: false,
    });
  }

  componentWillMount() {
    const projectHash = this.props.match.params.project_hash;
    const userId = parseInt(this.props.match.params.user_id);

    this.setState(
      {
        projectHash: projectHash,
        userId: userId,
      },
      () => {
        this.props.init(projectHash, userId);
      }
    );
  }

  componentWillUnmount() {
    this.props.reset();
  }

  componentWillReceiveProps(next) {
    if (this.props.timelines.length !== next.timelines.length) {
      this.setState({ covers: next.timelines.map((tl) => false) });
    }
  }

  closeMenu() {
    this.setState({ menu: false });
  }

  openMenu() {
    this.setState({ menu: true });
  }

  closeMemberMenu() {
    this.setState({ memberMenu: false });
  }

  openMemberMenu() {
    this.setState({ memberMenu: true});
  }

  copy() {
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  }

  description() {
    const content = this.props.projectSummary.description
      .split("\n")
      .map((s, key) => <p key={key}>{s}</p>);

    return (
      <div className={cn("dialog__description")}>
        <Linkify>{content}</Linkify>
      </div>
    );
  }

  toggleMenu() {
    this.setState({
      menu: !this.state.menu,
    });
  }

  toggleSourceMenu() {
    this.setState({
      sourceMenu: !this.state.sourceMenu,
    });
  }

  slide(index: number, postId: number, presentedId: number) {
      const tl = this.props.timelines[index];
      const scrollTop = document.getElementById(tl.tlId).scrollTop;
      const ref = tl.tlRef.querySelector(
        `#post_${postId}_presented_${presentedId}`
      );
      const rect = ref.getBoundingClientRect();
      const position = rect.top + scrollTop - 150;

      scroll.scrollTo(position, { containerId: tl.tlId });
  }

  getUser(tl: TimeLineType): UserType {
    let defaultUser: UserType = this.props.loginUserInfo;
    let lastUpdate = 0;

    if (!this.props.usersInfo) return defaultUser;

    let user = this.props.usersInfo[tl.userId];

    if (user === undefined) return defaultUser;

    if (tl.posts.length !== 0) {
      let lastGroup = [...tl.posts].reverse()[0];
      let lastPost = [...lastGroup.posts].reverse()[0];
      lastUpdate = lastPost.postTime * 1000;
    }

    return {
      iconUrl: user[1],
      name: user[0],
      lastUpdate: lastUpdate,
    };
  }

  getLastUpdate(userId: number): number {
    const tl = this.props.timelines.find((tl) => tl.userId === userId);
    if (!tl) return 0;
    if (tl.posts.length === 0) return 0;
    const lastPost = [
      ...tl.posts.map((group) => group.posts).flat(),
    ].reverse()[0];
    return lastPost.postTime * 1000;
  }

  setTlMemo(bool: boolean): void {
    this.setState({ tlMemo: bool });
  }

  toggleFilter() {
    this.setState({
      filtered: !this.state.filtered,
    });
  }

  render() {
    if (this.props.timelines.length === 0) return <Loader />;
    if (!this.props.projectSummary) return <Loader />;
    if (!this.props.joined) return <Loader />;

    return (
      <>
        <Header
          tlMemo={this.state.tlMemo}
          toggleNote={() => {
            this.setTlMemo(!this.state.tlMemo);
          }}
          toggleMenu={this.toggleSourceMenu}
          filtered={this.state.filtered}
          toggleFilter={this.toggleFilter}
          shared={false}
          tlId={null}
          projectSummary={this.props.projectSummary}
          labelClick={this.toggleMenu}
        />
        <div
          className="wrapper"
          style={{ width: 375 * this.props.timelines.length }}
        >
          {this.props.timelines.map((tl: TimeLineType, index: number) => (
            <div className={cn("container", { shared: tl.shared })} key={index}>
              <TimeLine
                {...tl}
                getUser={() => this.getUser(tl)}
                projectHash={this.state.projectHash}
                info={this.props.projectSummary}
                cover={this.state.covers[index]}
                toggleMenu={this.toggleMenu}
                toggleSourceMenu={this.toggleSourceMenu}
                sourceMenu={this.state.sourceMenu}
                filtered={this.state.filtered}
                slide={this.slide}
                getScrollPos={() =>
                  this.props.getScrollPos(this.state.projectHash, tl.userId)
                }
                setScrollPos={(pos) => {
                  this.props.setScrollPos(
                    this.state.projectHash,
                    tl.userId,
                    pos
                  );
                }}
                setTlMemo={this.setTlMemo.bind(this)}
                tlMemo={this.state.tlMemo}
                projectMemo={this.props.projectMemo}
              />
            </div>
          ))}

          {/* プロジェクト概要 */}
          <CSSTransition
            in={this.state.menu}
            classNames="menu"
            unmountOnExit
            timeout={100}
          >
            <Menu
              onClose={this.closeMenu}
              onDone={this.closeMenu}
              done=""
              slide
            >
              <div className={cn("dialog__header")}>
                {this.props.projectSummary.name}
              </div>

              {this.description()}

              <h2 className="menu__heading">プロジェクトメンバー</h2>
              <section className="menu__section">
                {Object.keys(this.props.usersInfo).map((id) => (
                  <User
                    key={id}
                    iconUrl={this.props.usersInfo[id][1]}
                    name={this.props.usersInfo[id][0]}
                    lastUpdate={this.getLastUpdate(parseInt(id))}
                  />
                ))}
              </section>

              <button className="menu__add-btn" onClick={this.openMemberMenu}>
                <div className="menu__add-btn-icon" />
                <span className="menu__add-btn-title">メンバー追加</span>
              </button>

              <h2 className="menu__heading">プロジェクトの共有URL</h2>
              <CopyToClipboard
                text={`${Config.DOMAIN}/projects/${this.state.projectHash}/${this.props.loginUserId}`}
                onCopy={this.copy.bind(this)}
              >
                <div
                  className={cn("copiable-text", {
                    "copiable-text--copied": this.state.copied,
                  })}
                >
                  <span className="copiable-text__message">
                    URLをコピーしました。
                  </span>
                  <span className="copiable-text__value">{`${Config.DOMAIN}/projects/${this.state.projectHash}/${this.props.loginUserId}`}</span>
                  <span className="copiable-text__icon">
                    <img src="/images/copy.svg" alt="" />
                  </span>
                </div>
              </CopyToClipboard>
            </Menu>
          </CSSTransition>

          {/* 参加メンバー編集 */}
          <CSSTransition
            in={this.state.memberMenu}
            classNames="menu"
            unmountOnExit
            timeout={100}
          >
            <EditUser
              onClose={this.closeMemberMenu}
              onDone={this.closeMemberMenu}
              selectedUsers={Object.keys(this.props.usersInfo).map((id) => ({
                id: id,
                info: this.props.usersInfo[id],
              }))}
            />
          </CSSTransition>

          {/* 参照ソース */}
          <CSSTransition
            in={this.state.sourceMenu}
            classNames="menu"
            unmountOnExit
            timeout={100}
          >
            <OtherMenu
              toggleMenu={this.toggleSourceMenu}
              toggleSource={this.props.toggleSource}
              togglePartOfSpeech={this.props.togglePartOfSpeech}
              sources={this.props.sources}
              partOfSpeech={this.props.partOfSpeech}
              projectId={this.props.projectId}
            />
          </CSSTransition>
        </div>
      </>
    );
  }
}

export default ProjectComponent;
