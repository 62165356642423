import moment from 'moment';

const fmt= 'YYYY/MM/DD HH:mm';
const locale= 'ja';

const date = (post_time:string) => {
  return moment(new Date(parseInt(post_time)*1000))
    .format(fmt);
}

const mergeProjects = data=>{
    let projects = [];
    data.myProjects.forEach((project, index) => {
      projects.push({ ...project, shared: false });
    });
    data.othersProjects.forEach((project, index) => {
      projects.push({ ...project, shared: true });
    });
    projects.sort((a, b) => {
      return b[0] - a[0];
    });

    return projects;
}

export  {date, mergeProjects};
