import { connect } from 'react-redux';
import * as Actions from '../../states/ducks/projects/actions';
import * as authActions from '../../states/ducks/auth/actions';
import Projects from '../pages/projects';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.projects
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    get: () => dispatch(Actions.get()),
    putUser: (name, iconImage) => {
      if (iconImage !== undefined) {
        dispatch(Actions.putUser({ name, iconImage }));
      } else {
        dispatch(Actions.putUser({ name }));
      }
    },
    logout: () => dispatch(authActions.logout()),
    create: (name, description) => dispatch(Actions.create({ name, description })),
    update: (projectId, name, description) =>
      dispatch(Actions.update({ projectId, name, description })),
    remove: id => dispatch(Actions.remove(id)),
    removeShared: projectHash => dispatch(Actions.remove_shared(projectHash))
  };
};

const ProjectsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Projects);

export default ProjectsContainer;
