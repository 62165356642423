import React, { Component } from "react";
import CheckBox from "../components/projects/checkbox";
import Menu from "../components/common/menu";
import { setup } from "bem-cn";

const block = setup({
  el: "__",
  mod: "--",
  modValue: "-",
});

const b = block("user-list");

type User = {
  employeeNumber: string;
  iconUrl: null | string;
  id: number;
  mailAddress: string;
  name: string;
};

interface Props {
  onClose(): void;
  onDone(): void;
  selectedUsers: any[];
  users: User[];
  getUsers(): void;
  putUsers(ids: number[]): void;
}
interface State {
  selectedUserIds: number[];
}

class NotFound extends Component<Props, State> {
  public state: State = {
    selectedUserIds: [],
  };

  constructor(props: Props) {
    super(props);
    this.onCheck = this.onCheck.bind(this);
    this.onDone = this.onDone.bind(this);
  }

  componentDidMount() {
    this.props.getUsers();
    this.setState({
      selectedUserIds: this.props.selectedUsers.map((user) =>
        parseInt(user.id)
      ),
    });
  }

  onCheck(id: number): void {
    const index = this.state.selectedUserIds.indexOf(id);
    let tmp = this.state.selectedUserIds;
    if (index >= 0) {
      tmp = tmp.filter((val) => val !== id);
    } else {
      tmp.push(id);
    }
    this.setState({
      selectedUserIds: tmp,
    });
  }

  isChecked(id: number): boolean {
    return this.state.selectedUserIds.indexOf(id) >= 0;
  }

  onDone(): void {
    this.props.putUsers(this.state.selectedUserIds);
    this.props.onDone();
  }

  render() {
    return (
      <Menu
        onClose={this.props.onClose}
        onDone={this.onDone}
        done="DONE"
        title={`${this.state.selectedUserIds.length}人を選択中`}
        titleAlign="left"
      >
        {this.props.users.map((user: User, key: number) => (
          <CheckBox
            name=""
            valid={this.isChecked(user.id)}
            toggleSource={() => this.onCheck(user.id)}
            key={user.id}
          >
            <div className={b()}>
              <div className={b("thumb")}>
                <span style={{ backgroundImage: `url(${user.iconUrl})` }} />
              </div>
              <div className={b("info")}>
                <p className={b("title")}>{user.name}</p>
                <p className={b("sub")}>{user.mailAddress}</p>
              </div>
            </div>
          </CheckBox>
        ))}
      </Menu>
    );
  }
}

export default NotFound;
