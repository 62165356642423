import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {setup} from 'bem-cn'
import {parse} from 'qs'
import Config from '../../states/config'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const b = block('sso')

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#591e6f'
    },
    secondary: {
      main: '#4dc9ec'
    }
  },
});

interface Props {
  setToken(access_token: string): void,
  isLogin: number,
  needSSO: boolean
}
interface State {
}

class Login extends Component<Props, State> {
  public state: State = {
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillMount() {
    const qs = parse(window.location.search.replace('?',''));
    if(qs.access_token) this.props.setToken(qs.access_token)
  }

  render() {
    if(!this.props.needSSO) return <Redirect to={{pathname: '/'}}/>
    if(this.props.isLogin >= 2) return <Redirect to={{pathname: '/'}}/>

    return (
      <div className={b()}>
        <MuiThemeProvider theme={theme}>
          <div className={b("title")}>
            <p>TANE</p>
            <span>ver. 0.9</span>
          </div>
          <a href={`https://casso.services.isca.jp/as/authorization.oauth2?response_type=code&client_id=tane&redirect_uri=${Config.DOMAIN}/api/callback&scope=openid%20email`} className={b("submit")}>LOGIN with CASSO</a>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default Login;
