import { connect } from 'react-redux';
import * as Actions from '../../states/ducks/login/actions';

import Login from '../pages/login';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...state.auth,
    ...state.login
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    login: state => dispatch(Actions.login(state))
  };
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default LoginContainer;
