import React, { Component } from 'react';
import './upload-photo.scss';
import {setup} from 'bem-cn';
import {getFile } from '../../../utils/fileManager'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});
const b = block('upload-photo')

interface Props {
  defaultImage: string | null,
  onReady(image:string): void
}

interface State {
  image: string | null,
}

class UploadPhoto extends Component<Props, State> {
  inputRef:any = null;

  public state: State = {
    image: null,
  }

  constructor(props:Props){
    super(props)
    this.onChange = this.onChange.bind(this)
    this.upload = this.upload.bind(this)
  }

  componentDidMount(){
    this.setState({image: this.props.defaultImage})
    this.inputRef.addEventListener('change',this.onChange)
  }

  componentWillUnmount(){
    this.inputRef.removeEventListener('change', this.onChange)
  }

  upload(e){
    this.inputRef.click()
  }

  onChange(e){
    getFile(e).then((result:any)=>{
      this.setState({image: result.url});
      this.props.onReady(result);
    }).catch(error=>{alert(error.message)});
  }

  render() {
    return (
      <section className={b()}>
        <label htmlFor="upload" className={b('preview')} style={{backgroundImage: `url("${this.state.image}")`}}>
          <input ref={node=>this.inputRef=node} name="upload" id="upload" type="file"/>
          <span className={b('btn')}><img src="/images/update-image.svg"/></span>
        </label>
      </section>
    );
  }
}

export default UploadPhoto;
