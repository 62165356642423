import createAsyncActions from '../../../utils/createAsyncActions';

const PREFIX = 'PROJECTS';
const SEPARATOR = '_';

//sync actions
const GET = PREFIX + SEPARATOR + 'GET';
const GET_USER = PREFIX + SEPARATOR + 'GET_USER';
const PUT_USER = PREFIX + SEPARATOR + 'PUT_USER';
const CREATE = PREFIX + SEPARATOR + 'CREATE';
const UPDATE = PREFIX + SEPARATOR + 'UPDATE';
const REMOVE = PREFIX + SEPARATOR + 'REMOVE';
const REMOVE_SHARED = PREFIX + SEPARATOR + 'REMOVE_SHARED';

//async actions
const get = createAsyncActions(GET);
const get_user = createAsyncActions(GET_USER);
const put_user = createAsyncActions(PUT_USER);
const update = createAsyncActions(UPDATE);
const create = createAsyncActions(CREATE);
const remove = createAsyncActions(REMOVE);
const remove_shared = createAsyncActions(REMOVE_SHARED);

export { get, get_user, put_user, create, update, remove, remove_shared };
