import React, { Component } from 'react';

interface Props {
  disableToolTip: any
}
interface State {}

class ToolTip extends Component<Props, State> {
  public state: State = {
  }

  render() {
    return (
      <div className="tooltip">
        <div className="tooltip__inner" onClick={this.props.disableToolTip}></div>
      </div>
    );
  }
}

export default ToolTip;
