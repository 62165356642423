import React, { Component } from 'react';
import Config from '../../../states/config';
import cn from 'classnames';
import {setup} from 'bem-cn'
import {Link} from 'react-router-dom';
import './header.scss'
import {Note} from '../common/icons'
import ProjectSummary from '../../../types/project-summary'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});
const b = block('header')

interface Props {
  projectSummary?: ProjectSummary,
  tlMemo: boolean,
  toggleNote(): void,
  toggleMenu: any,
  filtered: boolean,
  shared: boolean,
  toggleFilter(): void,
  tlId: string | null,
  labelClick(): void
}
interface State {
  scrollTop: number,
}

class HeaderComponent extends Component<Props, State> {
  public state: State = {
    scrollTop: 0,
  }

  constructor(props:Props){
    super(props);
    this.labelClick = this.labelClick.bind(this)
  }

  componentDidMount(){
    if(this.props.tlId)document.getElementById(this.props.tlId).addEventListener('scroll', this.updateScroll.bind(this));
  }

  componentWillUnmount() {
    if(this.props.tlId)document.getElementById(this.props.tlId).removeEventListener('scroll', this.updateScroll.bind(this));
  }

  updateScroll(e){
    this.setState({
      scrollTop: document.getElementById(this.props.tlId).scrollTop
    })
  }

  closeApp(e) {
    e.preventDefault();
    if ((window as any).confirm(Config.MESSAGE.CLOSEAPP)) {
      (window as any).close('about:blank', '_self');
    }
  }

  labelClick(){
    this.props.labelClick();
  }

  render() {
    const icon = this.props.filtered?'/images/sort.svg':'/images/filter.svg';
    return (
      <header className={cn('header', {["scroll-top"]:this.state.scrollTop < 30, shared: this.props.shared})}>
        <div className={b("inner")}>
          <div className={b("left")}>
            <Link className={b("btn")} to='/'>
              <img src="/images/arrow-left.svg"/>
            </Link>
          </div>
          <div className={b("center")}>
            <label onClick={this.labelClick}>{this.props.projectSummary?this.props.projectSummary.name:null}</label>
          </div>
          <div className={b("right")}>
            <button className={b("btn-note", {active: this.props.tlMemo})} onClick={this.props.toggleNote}>
              <Note/>
            </button>

            <button className={b("btn")} onClick={this.props.toggleFilter}>
              <img src={icon}/>
            </button>

            {!this.props.shared &&
              <button className={b("btn")} onClick={this.props.toggleMenu}>
                <img src="/images/menu.svg"/>
              </button>
            }
          </div>
        </div>
      </header>
    );
  }
}

export default HeaderComponent;
