import { connect } from 'react-redux';
import React, { Component } from 'react';
import * as Actions from '../../../states/ducks/project/actions';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    error: state.project.error
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    removeError: () => dispatch(Actions.error(null)),
  };
};

interface Props {
  error: string | null,
  removeError:any
}
interface State {}

class AlertComponent extends Component<Props, State> {
  public state: State = {
  }

  render() {
    if (!this.props.error) return null;
    return (
      <header className="notification" onClick={this.props.removeError}>
        <div className="notification__inner">{this.props.error}</div>
      </header>
    );
  }
}

const Alert = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertComponent);

export default Alert;
