import React, { Component, Fragment } from 'react';
import Menu from '../components/common/menu';
import ProjectItem from '../components/projects/project-item';
import { CSSTransition } from 'react-transition-group';
import {setup} from 'bem-cn';
import TextareaAutosize from 'react-textarea-autosize';
import UploadPhoto from '../components/projects/upload-photo';
import TextInput from '../components/projects/text-input';
import ProjectSummary from '../../types/project-summary'
import User from '../../types/user'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});
const b = block('projects')
const m = block('menu')

interface Props {
  get: any,
  putUser(username: string, icon?:string): void,
  logout(): void,
  create: any,
  update(id:number, name:string, description:string): void,
  remove(id:number): void,
  removeShared(id:number): void,
  projects: Array<ProjectSummary>,
  user: User | null
}

interface State {
  menu: boolean,
  profile: boolean,
  username: string,
  iconURL: string | null,
  iconFile: any | null,
  iconNeedsUpdate: boolean,
  editable: boolean,
  projectName: string,
  projectDesciption: string,
  selectedId: string | null
}

class Projects extends Component<Props, State> {
  public state: State = {
    menu: false,
    profile: false,
    username: '',
    iconURL: null,
    iconFile: null,
    iconNeedsUpdate: false,
    projectName: '',
    projectDesciption: '',
    editable: false,
    selectedId: null
  }

  constructor(props:Props){
    super(props);
    this.remove = this.remove.bind(this)
    this.send = this.send.bind(this)
    this.putProfile= this.putProfile.bind(this)
    this.userImageReady = this.userImageReady.bind(this)
  }

  toggleMenu(e){
    if(this.state.menu) {
      this.setState({
        menu: false,
        projectName: '',
        projectDesciption: '',
        selectedId: null
      })
    }else {
      this.setState({
        menu: true
      })
    }
  }

  toggleProfile(){
    this.setState({
      profile: !this.state.profile
    })
  }

  componentWillReceiveProps(nextProps) {
    if(!nextProps.user)return

    this.setState({
      username: nextProps.user.name,
      iconURL: nextProps.user.iconUrl,
    })
  }

  toggleEdit(){
    this.setState({editable: !this.state.editable})
  }

  componentDidMount() {
    this.props.get();

    if(window.document.getElementById('react-app'))window.document.getElementById('react-app').removeAttribute('class');
    if(window.document.getElementById('app'))window.document.getElementById('app').scrollTo(0, 0);
  }

  send(e){
    if(this.state.selectedId) {
      this.props.update(parseInt(this.state.selectedId), this.state.projectName, this.state.projectDesciption);
    }else {
      this.props.create(this.state.projectName, this.state.projectDesciption);
    }
    this.setState({
      menu: false,
      selectedId: null,
      editable: false,
      projectName: '',
      projectDesciption: ''
    })
  }

  remove(obj){
      if(!obj.shared){
        if(window.confirm('このプロジェクトを削除しますか？')){
          this.props.remove(obj.projectId);
        }
      }else{
        if(window.confirm('このプロジェクトから退室しますか？\n※あなたのタイムラインは残ります')){
          this.props.removeShared(obj.projectHash);
        }
      }
    this.setState({
      editable: false,
    })
  }

  edit(id){
    const project:ProjectSummary = this.props.projects.filter(project=>project.id === id)[0]

    this.setState({
      menu: true,
      selectedId: id,
      projectName: project.name,
      projectDesciption: project.description
    })
  }

  componentWillUpdate(){
    if(this.props.projects.length === 0 && this.state.editable){
      this.setState({editable: false});
    }
  }

  userImageReady(result){
    this.setState({iconURL: result.url, iconFile: result.file, iconNeedsUpdate: true})
  }

  putProfile(){
    if(this.state.iconNeedsUpdate){
      this.props.putUser(this.state.username, this.state.iconFile)
    }else{
      this.props.putUser(this.state.username)
    }

    this.setState({
      iconNeedsUpdate: false,
      profile: false
    })
  }

  render() {
    return (
      <Fragment>
      <CSSTransition in={this.state.menu} classNames={m()} unmountOnExit timeout={100}>
        <Menu onClose={this.toggleMenu.bind(this)} onDone={this.send} done="OK" disabled={this.state.projectName.length === 0}>
          <h2 className={m("heading")}>新規プロジェクト</h2>
          <input className={m("content-title", {empty: this.state.projectName.length <= 0} )} type="text" placeholder='Project Name' value={this.state.projectName} onChange={e=>this.setState({projectName: e.target.value})}/>

          <h2 className={m("heading")}>概要</h2>
          <section className={m("text-area", {empty: this.state.projectDesciption.length <= 0})}>
            <TextareaAutosize placeholder="Aa" minRows={5} value={this.state.projectDesciption} onChange={e=>this.setState({projectDesciption: e.target.value})} />
          </section>
        </Menu>
      </CSSTransition>

      {/** プロフィール編集 */}
      <CSSTransition in={this.state.profile} classNames={m()} unmountOnExit timeout={100}>
        <Menu title="PROFILE" onClose={this.toggleProfile.bind(this)} onDone={this.putProfile} done="OK" disabled={this.state.username.length === 0}>
          <div className={b("profile")}>
          <UploadPhoto defaultImage={this.state.iconURL} onReady={this.userImageReady}/>

          <h2 className={m("heading")}>名前</h2>
          <TextInput placeholder='Your Name' defaultValue={this.state.username} onChange={e=>this.setState({username: e.target.value})}/>
          <button className={b("logout-btn")} onClick={this.props.logout}><img src="/images/logout.svg"/></button>
          </div>
        </Menu>
      </CSSTransition>

      <div className={b()}>
      <div className={b("wrapper")}>
        <div className={b("header")}>
          <div className={b("header-left")}>
            <button onClick={this.toggleProfile.bind(this)}>
              <img src="/images/profile.svg" alt=""/>
            </button>
          </div>
          <div className={b("header-center")}>
            <h1>PROJECT</h1>
          </div>
          <div className={b("header-right")}>
            <button onClick={this.toggleEdit.bind(this)} className={b("btn", {editable: this.state.editable})}>{this.state.editable?"DONE":'EDIT'}</button>
          </div>
        </div>

        <div className={b("content", {editable: this.state.editable})}>
          {this.props.projects.sort((a,b)=>b.postTime - a.postTime).map(project=>(
            <ProjectItem
              {...project}
              edit={()=>{this.edit(project.id)}}
              remove={this.remove}
              userId={this.props.user?this.props.user.id:null}
              key={project.id}/>
          ))}
        </div>
        <div className={b("add")} onClick={this.toggleMenu.bind(this)}>
          <span><img src="/images/add-project.svg" alt=""/></span>
        </div>
      </div>
      </div>
      </Fragment>
    );
  }
}

export default Projects;
