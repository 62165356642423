import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './javascripts/states/store';
import Auth from './javascripts/views/containers/auth';
import Login from './javascripts/views/containers/login';
import SSO from './javascripts/views/containers/sso';
import Projects from './javascripts/views/containers/projects';
import Project from './javascripts/views/containers/project';
import NotFound from './javascripts/views/containers/not-found';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {/** <Route path="/" exact component={Index} />*/}
          <Route path="/login" exact component={Login} />
          <Route path="/sso" exact component={SSO} />
          <Auth>
            <Switch>
              <Route path="/" exact component={Projects} />
              <Route path="/projects/:project_hash/:user_id" component={Project} />
            </Switch>
          </Auth>
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
