import { createAction } from 'redux-actions';
import req from '../../../utils/req';
import Cookies from 'js-cookie';
import { mergeProjects } from '../../../utils/utils';
import PostWord from '../../../types/post-word'
import PostWordArg from '../../../types/post-word-arg'

import * as types from './types';

const error = createAction(types.ERROR);
const queue_action = createAction(types.QUEUE);
const put_text = createAction(types.PUT_TEXT);
const to_bottom = createAction(types.TO_BOTTOM);
const ready_to_say = createAction(types.READY_TO_SAY);
const start_to_say = createAction(types.START_TO_SAY);
const reset = createAction(types.RESET);
const toggle_source = createAction(types.TOGGLE_SOURCE);
const toggle_part_of_speech = createAction(types.TOGGLE_PART_OF_SPEECH);
const toggle_note = createAction(types.TOGGLE_NOTE)
const enable_tooltip = createAction(types.ENABLE_TOOLTIP)
const disable_tooltip = createAction(types.DISABLE_TOOLTIP)
const set_tl_ref = createAction(types.SET_TL_REF)
const get_project_info_action = createAction(types.GET_PROJECT_INFO)
const reset_related_word = createAction(types.RESET_RELATED_WORD)
const set_copied_user = createAction(types.SET_COPIED_USER)

const getPostData = (groups, params) => {
  const filtered = groups.filter(group => {
    return (
      group.postId === params.postId &&
      group.posts.filter(post => post.presentedId === params.presentedId).length > 0
    );
  });

  let post = filtered[0].posts.filter(post => post.presentedId === params.presentedId)[0];

  return {
    isPin: post.isPin ? '1' : '0',
    isSelected: post.isSelected ? '1' : '0',
    memo: post.memo,
    ...params
  };
};

//プロジェクトを取得
const get_project = payload => async (dispatch, getState) => {
  dispatch(types.get_project.pending());
  const res = await req(
    'GET_PROJECT',
    { params: payload , data: {} },
    Cookies.get('token')
  ).catch(e => {
    dispatch(types.get_project.rejected(e));
    return;
  });

  if (res) {
    //取得したプロジェクトが自分のプロジェクト一覧にない場合
    const projectIds = getState().projects.projects.map(project=>project.id)

    //postedUserIdsのそれぞれのpostsを再取得
    const tlRes = await Promise.all(res.data.postedUserIds.map(async id=>{
      //自分のidと一致する場合すでに所得済なのでスキップ
      if(id === payload.userId) {
        const data = res.data;
        data.userId = id;
        data.shared = false;
        return data;
      } ;

      const tlRes = await req('GET_PROJECT',{ params: {...payload, userId: id}, data: {} }, Cookies.get('token'));
      tlRes.data.userId = id;
      tlRes.data.shared = true;
      return tlRes.data;
    }));

    let haveMyTl = tlRes.filter((data:any)=>data.userId === payload.userId).length > 0;

    //並び替え
    if(haveMyTl){
      const index = tlRes.map((data:any)=>data.userId).indexOf(payload.userId);
      const tlData = tlRes[index]
      tlRes.splice(index,1)
      tlRes.unshift(tlData)
    }

    if(tlRes) {
      dispatch(
        types.get_project.fulfilled({
          ...payload,
          data: res.data,
          timelines: haveMyTl?tlRes:[{...res.data, userId: payload.userId}, ...tlRes]
        })
      );

      if(projectIds.indexOf(res.data.projectId)<0) {
        if(window.confirm('このプロジェクトに参加しますか？')){
          dispatch(shared_project(payload))
          dispatch(get_project_info_action(res.data));
        }else{
          window.location.pathname = '/'
        }
      }else{
        dispatch(types.shared_project_status.fulfilled());
      }
    }
  }
};

const get_project_info = payload => (dispatch, getState) => {
  //プロジェクト名を取得
  const info = getState().projects.projects.filter(project=>project.url === payload.projectHash);

  dispatch(get_project_info_action(info[0]));
};

//発話
const say = payload => (dispatch, getState) => {
  const state = getState();
  const queue = state.project.timelines.filter(tl=>parseInt(tl.userId) === parseInt(payload.userId))[0].queue
  const words = [...queue, payload.text];
  dispatch(start_to_say());

  let txt_param:any = {
      userId: parseInt(payload.userId),
  }

  txt_param.data = [{
    word: words,
    isPin: false,
    memo: '',
    postTime: Date.now()/1000,
    speakerId: 'me'
  }]

  if(payload.sourceId){
    txt_param.data[0].sourceId = payload.sourceId;
    txt_param.data[0].referrerUser = {
      referrerPostId: payload.referrerPostId,
      referrerPresentedId: payload.referrerPresentedId
    }
  }

  dispatch(put_text(txt_param));
  setTimeout(() => {
    dispatch(to_bottom({userId: payload.userId, duration:300}));
  }, 100);
};

//バックグラウンドのTLに発話
const sayInBackground = payload => (dispatch, getState) => {
  const words = [payload.text];
  dispatch(
    put_text({
      userId: parseInt(payload.userId),
      data: [{
        word: words,
        isPin: false,
        memo: '',
        postTime: Date.now()/1000,
        speakerId: 'me',
        referrerUser: payload.referrerUser,
        sourceId: payload.sourceId,
        postId: payload.postId
      }]
    })
  );
};

//キューとして発話
const queue = payload => (dispatch, getState) => {
  dispatch(queue_action(payload));
  dispatch(
    put_text({
      userId: payload.userId,
      data: [{
        word: [payload.text],
        isPin: false,
        isQueued: true,
        memo: '',
        postTime: Date.now()/1000,
        speakerId: 'queue'
      }]
    })
  );
  setTimeout(() => {
    dispatch(to_bottom(300));
  }, 100);
};

//発話+送信
const send = payload => (dispatch, getState) => {
  let data:any = {
    userId: payload.userId,
    text: payload.text
  }

  if(payload.sourceId) {
    data.sourceId = payload.sourceId;
    data.referrerPostId = payload.postId;
    data.referrerPresentedId = payload.presentedId;
  }

  dispatch(say(data));

  if(payload.postId)dispatch(select(payload));

  let sendData:any = {
    userId: payload.userId,
    isQueued: false,
    text: payload.text,
    projectId: payload.projectId,
  }

  if(payload.sourceId) {
    sendData.sourceId = payload.sourceId;
    sendData.referrerPostId = payload.postId;
    sendData.referrerPresentedId = payload.presentedId;
  }

  dispatch(post_word(sendData));
};

//送信のみ
const sendQuietly = payload => (dispatch, getState) => {
  dispatch(
    post_word({
      userId: payload.userId,
      projectId: payload.projectId,
      postId: payload.postId,
      text: payload.text,
      isQueued: false
    })
  );
};

//発話+キュー送信
const sendQueue = payload => (dispatch, getState) => {
  dispatch(queue(payload));
  dispatch(select(payload));
  dispatch(
    post_word({
      userId: payload.userId,
      projectId: payload.projectId,
      isQueued: true,
      text: payload.text
    })
  );
};

//キューに溜まったワードのみ送信
const sendOnlyQueue = payload => (dispatch, getState) => {
  const state = getState()
  const queue = state.project.timelines.filter(tl=>parseInt(tl.userId) === parseInt(payload.userId))[0].queue
  dispatch(
    put_text({
      userId: payload.userId,
      data: [{
        word: queue,
        isPin: false,
        memo: '',
        postTime: Date.now()/1000,
        speakerId: 'me'
      }]
    })
  );

  dispatch(
    post_word({
      userId: payload.userId,
      projectId: payload.projectId,
      isQueued: false
    })
  );
};

//他人のワードをコピーする
const copy = payload =>  async (dispatch, getState) => {
  const loginUser = getState().projects.user;

  const data:PostWord = {
    projectId: payload.projectId,
    words: payload.text,
    isQueued: '0',
    sourceId: 6
  }

  dispatch(types.post_copy_word_status.pending());

  const copyRes = await req('POST_COPY_WORD', { params: {}, data: {
    referrerUserId: payload.userId,
    projectId: payload.projectId,
    referrerPostId: payload.postId,
    referrerPresentedId: payload.presentedId
  }}, Cookies.get('token')).catch(e =>
    dispatch(types.post_copy_word_status.rejected())
  );

  const res = await req('POST_WORDS', {params: {}, data}, Cookies.get('token')).catch(e =>
    dispatch(types.get_status.rejected(e))
  );

  if(res) {
    dispatch(sayInBackground({
      postId: res.data.request_post_id,
      userId: loginUser.id,
      text: payload.text,
      referrerUser: {
        referrerUserId: payload.userId,
        referrerPostId: payload.postId,
        referrerPresentedId: payload.presentedId
      },
      sourceId: 6,
    }));
  }

  if (res && copyRes) {
    dispatch(put_text({
      userId: parseInt(loginUser.id),
      data: res.data.words
    }));

    dispatch(set_copied_user({
      copiedUserId: loginUser.id,
      copiedPostId: res.data.request_post_id,
      originalUserId: payload.userId,
      originalPostId: payload.postId,
      originalPresentedId: payload.presentedId
    }))
  }
};

//送信する
const post_word = (payload: PostWordArg)=> async (dispatch, getState) => {
  dispatch(types.get_status.pending());
  let words;
  const state = getState();
  const queue = state.project.timelines.filter(tl=>parseInt(tl.userId) === parseInt(payload.userId))[0].queue

  if (payload.isQueued) {
    words = [payload.text].join('&');
  } else if (typeof payload.text === 'undefined') {
    words = [...queue].join('&');
  } else {
    words = [...queue, payload.text].join('&');
  }

  let data:PostWord = {
    projectId: payload.projectId,
    words: words,
    isQueued: payload.isQueued ? '1' : '0',
    corpus: state
      .project.sources.filter(source => source.valid)
      .map(source => source.name)
      .join('&'),
    partOfSpeech: state
      .project.partOfSpeech.filter(pos=>pos.valid)
      .map(pos=> pos.name)
      .join('&')
  };

  //more送信の際のパラメータ
  if (payload.postId) data.postId = payload.postId;

  //区切り線追加
  if (payload.sourceId) {
    data.sourceId = payload.sourceId;
    data.referrerPostId = payload.referrerPostId;
    data.referrerPresentedId = payload.referrerPresentedId;
  }

  const res = await req('POST_WORDS', { params: {}, data }, Cookies.get('token')).catch(e =>
    dispatch(types.get_status.rejected(e))
  );

  if(res.error)return;

  dispatch(types.get_status.fulfilled(payload));
  dispatch(put_text({
    userId: payload.userId,
    data: res.data.words
  }));
  dispatch(ready_to_say());
  dispatch(disable_tooltip());

  setTimeout(() => {
    dispatch(to_bottom({userId: payload.userId, duration: 300}));
  }, 100);
};

//pinする
const toggle_pin = payload => async (dispatch, getState) => {
  dispatch(types.toggle_pin_status.pending());
  const posts = getState().project.timelines.filter(tl=>parseInt(tl.userId) === parseInt(payload.userId))[0].posts;

  const res = await req(
    'PUT_POST',
    { data: getPostData(posts, payload), params:{} },
    Cookies.get('token')
  ).catch(e => {
    dispatch(types.toggle_pin_status.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.toggle_pin_status.fulfilled(payload));
  }
};

//noteする
const note = payload => async (dispatch, getState) => {
  dispatch(types.note_status.pending());
  const posts = getState().project.timelines.filter(tl=>parseInt(tl.userId) === parseInt(payload.userId))[0].posts;

  const res = await req(
    'PUT_POST',
    { data: getPostData(posts, payload) ,params:{}},
    Cookies.get('token')
  ).catch(e => {
    dispatch(types.note_status.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.note_status.fulfilled(payload));
    dispatch(disable_tooltip());
  }
};

//selectする

interface selectArg {
  userId: string,
  presentedId: number,
  postId: number,
}

const select = (payload: selectArg) => async (dispatch, getState) => {
  dispatch(types.selected_status.pending());
  const posts = getState().project.timelines.filter(tl=>parseInt(tl.userId)===parseInt(payload.userId))[0].posts

  const res = await req(
    'PUT_POST',
    { data: {...getPostData(posts, payload), isSelected:'1'} ,params:{}},
    Cookies.get('token')
  ).catch(e => {
    dispatch(types.selected_status.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.selected_status.fulfilled(payload));
  }
};

//shared projectにする
const shared_project = payload=> async (dispatch, getState) => {
  dispatch(types.shared_project_status.pending());

  const res = await req(
    'POST_SHARED_PROJECT',
    { data: { projectHash: payload.projectHash } ,params:{}},
    Cookies.get('token')
  ).catch(e => {
    dispatch(types.shared_project_status.rejected(e));
  });

  const projectsRes = await req(
    'GET_PROJECT',
    { params: payload , data: {} },
    Cookies.get('token')
  ).catch(e => {
    dispatch(types.get_project.rejected(e));
    return;
  });

  if (res && projectsRes) {
    window.location.pathname = '/'
  }
};

//TLメモを送信する
const send_tl_memo = payload=> async (dispatch, getState) => {
  const user = getState().projects.user;
  dispatch(types.post_others_status.pending());

  const res = await req(
    'POST_OTHERS_MEMO',{ data: payload ,params:{}},Cookies.get('token')
  ).catch(e => {
    dispatch(types.post_others_status.rejected(e));
  });

  if (res) dispatch(types.post_others_status.fulfilled({data:payload, user: user}));
};

//TLメモを更新する
const update_tl_memo = payload=> async (dispatch, getState) => {
  const user = getState().projects.user;
  dispatch(types.put_others_status.pending());

  const res = await req(
    'PUT_OTHERS_MEMO',{ data: payload ,params:{}},Cookies.get('token')
  ).catch(e => {
    dispatch(types.put_others_status.rejected(e));
  });

  if (res) dispatch(types.put_others_status.fulfilled({data:payload, user: user}));
};

//TLの情報を保存
const setTlRef = payload => (dispatch, getState) => {
  dispatch(set_tl_ref(payload));
};

//関連語を取得
const post_related_word = payload=> async (dispatch, getState) => {
  let endpoint;
  let data:any = {
    word: payload.word
  }

  switch(payload.type){
    case 'dictionary':
      endpoint = 'POST_DICTIONARY'
      break;
    case 'combine':
      endpoint = 'POST_PAIRWORD'
      data.returnedIds = getState().project.relatedWords.words.length?getState().project.relatedWords.words.map(obj=>obj.id).join(','):null
      break;
    case 'phrase':
      endpoint = 'POST_PHRASE'
      data.returnedIds = getState().project.relatedWords.words.length?getState().project.relatedWords.words.map(obj=>obj.id).join(','):null
      break;
  }

  const res = await req(
    endpoint,{ data ,params:{}},Cookies.get('token')
  ).catch(e => {
    dispatch(types.post_related_word_status.rejected(e));
  });

  if (res) dispatch(types.post_related_word_status.fulfilled({data: res.data, type: payload.type}));
};

//PROJECTメモを送信する
const send_project_memo = (payload) => async (dispatch, getState) => {
  const projectId = getState().project.projectId;
  dispatch(types.put_project_memo_status.pending());

  const res = await req(
    "PUT_PROJECT",
    { data: { ...payload, projectId }, params: {} },
    Cookies.get("token")
  ).catch((e) => {
    dispatch(types.put_project_memo_status.rejected(e));
  });

  if (res) dispatch(types.put_project_memo_status.fulfilled(payload.memo));
};

//PROJECTメンバーを更新する
const send_project_members = payload=> async (dispatch, getState) => {
  const projectId = getState().project.projectId;
  const users = getState().util.users;
  dispatch(types.put_project_members.pending());

  const res = await req(
    'PUT_PROJECT',{ data: {accountIds: payload.join('&'), projectId},params:{}},Cookies.get('token')
  ).catch(e => {
    dispatch(types.put_project_members.rejected(e));
  });

  if (res) dispatch(
    types.put_project_members.fulfilled(users.filter((user) => payload.indexOf(user.id)>=0))
  );
};

//POSTを削除する
const remove_post = payload=> async (dispatch, getState) => {
  dispatch(types.remove_post.pending());
  const user = getState().projects.user;
  const timeline = getState().project.timelines.find(tl=>tl.userId === user.id);
  const group = timeline.posts.find(group=>group.postId === payload.postId+1 && group.type === 'you')

  const res = await req(
    'PUT_POST',{ data: {...payload, isDeleted:"1"},params:{}},Cookies.get('token')
  ).catch(e => {
    dispatch(types.remove_post.rejected(e));
  });

  if (res) dispatch(
    types.remove_post.fulfilled({...payload, userId: user.id})
  );

  //次のPOST郡も削除
  if (timeline && group && group.posts.length) {
    await Promise.all(group.posts.map(async post => {
      const res = await req(
        'PUT_POST',{ data: {projectId: payload.projectId, postId: post.postId, presentedId: post.presentedId, isDeleted:"1"},params:{}},Cookies.get('token')
      ).catch(e => {
        dispatch(types.remove_post.rejected(e));
      });
      if (res) dispatch(
        types.remove_post.fulfilled({...payload, postId:payload.postId+1, userId: user.id})
      );
    }))
  }
};

export {
  get_project,
  //get_timeline,
  get_project_info,
  error,
  queue,
  copy,
  send,
  sendQueue,
  sendQuietly,
  sendOnlyQueue,
  reset,
  toggle_pin,
  note,
  toggle_source,
  toggle_part_of_speech,
  toggle_note,
  enable_tooltip,
  disable_tooltip,
  shared_project,
  setTlRef,
  send_tl_memo,
  update_tl_memo,
  post_related_word,
  reset_related_word,
  send_project_memo,
  send_project_members,
  remove_post,
};
