import React, { Component, Fragment } from 'react';
import Menu from '../common/menu';
import {setup} from 'bem-cn'
import CheckBox from '../projects/checkbox'
import Radio from '../common/radio'
import More from './more'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const b = block('note')
const m = block('menu')

interface Props {
  toggleMenu(): void,
  toggleRelated(type:string, word:string): void,
  sendWord(text, sourceId:any): void,
  sendNote(text): void,
  title: string,
  type: 'dictionary'|'combine'|'phrase',
  words: any[] | null
}
interface State {
  id: string,
  selectedCombineWordId: number|null,
  selectedPhrase: any[]
}

class Note extends Component<Props, State> {
  public state: State = {
    id: Math.random().toString(36).slice(2),
    selectedCombineWordId: null,
    selectedPhrase: []
  }

  constructor(props:Props){
    super(props)
    this.send = this.send.bind(this)
    this.done = this.done.bind(this)
    this.selectPhrase = this.selectPhrase.bind(this)
    this.toggleWords = this.toggleWords.bind(this)
    this.updateWords = this.updateWords.bind(this)
    this.isSelectedPhrase = this.isSelectedPhrase.bind(this)
    this.getNotice = this.getNotice.bind(this)
  }

  send(word, sourceId){
    this.props.sendWord(word, sourceId)
    this.props.toggleMenu()
  }

  getWordById(id){
    const existed = this.props.words.find(obj=> obj.id === id)
    return existed.word
  }

  done(){
    switch(this.props.type){
      case 'dictionary':
        return undefined
        break;
      case 'combine':
        return ()=>{
          this.props.sendWord([this.props.title, this.getWordById(this.state.selectedCombineWordId)].join('&'), 4)
          this.props.toggleMenu()
        }
        break;
      case 'phrase':
        let words = this.props.words.filter(obj=>{
          const existed = this.state.selectedPhrase.find(p=>obj.id === p.id);
          return existed.selected
        }).map(obj=>obj.phrase);

        return ()=>{
          this.props.sendNote(words.join('\n\n'))
          this.props.toggleMenu()
        }
        break;
    }
  }

  subtitle(){
    switch(this.props.type){
      case 'dictionary':
        return ''
      case 'combine':
        return 'との組合せ'
      case 'phrase':
        return 'のフレーズ'
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.type !== 'phrase')return
    this.setState({
      selectedPhrase: nextProps.words.map(obj=> {
        let existed = this.state.selectedPhrase.find(phrase=>phrase.id === obj.id);
        return {id:obj.id, selected: existed?existed.selected:false}
      })
    })
  }

  selectPhrase(k){
    this.setState({
      selectedPhrase: this.state.selectedPhrase.map((obj,key)=>{
        if(obj.id===parseInt(k)){
          return {id:obj.id, selected: !obj.selected}
        }else{
          return obj
        }
      })
    })
  }

  isSelectedPhrase(id){
    const existed = this.state.selectedPhrase.find(obj=>obj.id===id);
    return existed.selected
  }

  toggleWords(id){
    this.setState({
      selectedCombineWordId: id
    })
  }

  updateWords(){
    this.props.toggleRelated(this.props.type, this.props.title)
  }

  getNotice(words:string):string {
    switch(words){
      case '同義語':
        return '特になし';
      case '対義語':
        return '開発中';
      case '関連語':
        return '開発中';
    }
  }

  render() {
    return (
      <Menu onClose={this.props.toggleMenu} onDone={this.done()} done="OK">
        <div className={b()}>
          <h2 className={b("title")}>
            <span>{this.props.title}</span>
            {this.subtitle()}
          </h2>

          {this.props.type === 'dictionary' && this.props.words &&
            Object.keys(this.props.words).map(words=>(
              <Fragment key={words}>
              <h3 className={m("heading")}>{words}</h3>
              {this.props.words[words].length > 0 ?
                <ul className={b('selection-wrapper', {column: true})}>
                  {this.props.words[words].map(word=>{
                    let sourceId;
                    switch(words){
                      case '同義語':
                        sourceId = 1;
                        break;
                      case '対義語':
                        sourceId = 2;
                        break;
                      case '関連語':
                        sourceId = 3;
                        break;
                    }
                    return (
                      <li className={b('selection')} key={word} onClick={()=>{this.send(word, sourceId)}}>
                        <span className={b('selection-item')}>{word}</span>
                      </li>
                    )
                  }
                  )}
                </ul>
                :
                <span className={m("notice")}>{this.getNotice(words)}</span>
                }
              </Fragment>
            ))
          }

          {this.props.type === 'combine' && this.props.words &&
            <Fragment>
            <ul className={b('selection-wrapper')}>
              {this.props.words.map((obj,key)=>{
                return (
                  <li className={b('selection')} key={key}>
                    <span className={b('selection-item')}>
                      <Radio checked={obj.id === this.state.selectedCombineWordId} check={()=>{this.toggleWords(obj.id)}}>
                        {this.props.title}<span>+</span>{obj.word}
                      </Radio>
                    </span>
                  </li>
                )
              }
              )}
            </ul>
            <More onClick={this.updateWords}/>
            </Fragment>
          }

          {this.props.type === 'phrase' && this.props.words &&
            <Fragment>
            <ul className={b('selection-wrapper')}>
              {this.props.words.map((obj, key)=>{
                return (
                  <li className={b('selection')} key={key}>
                    <span className={b('selection-item')}>
                      <CheckBox right name='aaa' valid={this.isSelectedPhrase(obj.id)} toggleSource={()=>{this.selectPhrase(obj.id)}}>
                        {obj.phrase}
                      </CheckBox>
                    </span>
                  </li>
                )
              })}
            </ul>
            <More onClick={this.updateWords}/>
            </Fragment>
          }
        </div>
      </Menu>
    );
  }
}

export default Note;
