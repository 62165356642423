import React, { Component, Fragment } from 'react';
import Header from '../components/project/header';
import Alert from '../components/project/alert';
import TimelineNote from '../components/project/timeline-note';

import ToolTip from '../components/projects/tool-tip';
import NoteInput from "../components/project/note";
import { CSSTransition } from 'react-transition-group';
import TextInput from '../components/project/text-input';
import DialogGroup from '../components/project/dialog-group';
import cn from 'classnames';
import Group from '../../types/group';
import User from '../components/project/user'
import {setup} from 'bem-cn'
import RelatedWords from '../components/project/related-words';
import ProjectSummary from '../../types/project-summary'
import TimeLine from '../../types/timeline'
import UserType from '../../types/user'
import FA from 'react-fontawesome';
import {Note} from '../components/common/icons'
import Linkify from 'react-linkify'
import More from '../components/project/more'

import '../../../sass/components/_dots.scss'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const d = block('dialog')

interface Props extends TimeLine {
  info: ProjectSummary;
  sendOnlyQueue(userId: number, projectId: number): any;
  send(
    userId: number,
    projectId: number,
    postId: number | null,
    presentedId: number | null,
    text: string,
    sourceId?: any
  ): void;
  sendQuietly(
    userId: number,
    projectId: number,
    postId: number,
    text: string
  ): any;
  match: any;
  cover: boolean;
  toggleNote(toggle?: boolean): void;
  sendNote(
    userId: number,
    projectId: number,
    postId: number,
    presentedId: number,
    note: string
  ): any;
  disableToolTip(): void;
  projectHash: string;
  projectId: number | null;
  usersInfo: any[];
  loginUserInfo: UserType;
  setTlRef(userId: number, tlId: string, tlRef: any): void;
  sendTlMemo(postId: number, tlUserId: number, projectId: number, memo: string);
  sendProjectMemo(memo: string);
  updateTlMemo(
    postId: number,
    presentedId: number,
    tlUserId: number,
    projectId: number,
    speakerId: number,
    memo: string
  );
  removeTlMemo(
    postId: number,
    presentedId: number,
    tlUserId: number,
    projectId: number,
    speakerId: number
  );
  toggleMenu(): void;
  toggleSourceMenu(): void;
  relatedWords: any;
  postRelatedWord(type: string, word: string): void;
  resetRelatedWord(): void;
  slide(index: number, postId: number, presentedId: number): void;
  getUser(): UserType;
  getScrollPos(): number;
  setScrollPos(pos: number): void;
  isPc: boolean;
  setTlMemo(bool: boolean): void;
  tlMemo: boolean;
  projectMemo: string;
  filtered: boolean;
  sourceMenu: boolean;
  toggleFilter(): void;
}

interface State {
  tlMemoModal: boolean;
  projectMemoModal: boolean;
  tlMemoPostId: number | null;
  tlMemoPresentedId: number | null;
  tlMemoSpeakerId: number | null;
  related: boolean;
  id: string;
  toggleDescription: boolean;
  loaded: boolean;
}

class TimeLineComponent extends Component<Props, State> {
  private timelineNode: any = null;

  public state: State = {
    tlMemoModal: false,
    projectMemoModal: false,
    tlMemoPostId: null,
    tlMemoPresentedId: null,
    tlMemoSpeakerId: null,
    related: false,
    id: `tlContainer_${Math.random().toString(36).slice(2)}`,
    toggleDescription: false,
    loaded: false,
  };

  constructor(props: Props) {
    super(props);
    this.disableToolTip = this.disableToolTip.bind(this);
    this.sendQuietly = this.sendQuietly.bind(this);

    this.createTlMemo = this.createTlMemo.bind(this);
    this.removeTlMemo = this.removeTlMemo.bind(this);
    this.closeTlMemo = this.closeTlMemo.bind(this);

    this.sendProjectMemo = this.sendProjectMemo.bind(this);
    this.createProjectMemo = this.createProjectMemo.bind(this);
    this.removeProjectMemo = this.removeProjectMemo.bind(this);
    this.closeProjectMemo = this.closeProjectMemo.bind(this);

    this.toggleDescription = this.toggleDescription.bind(this);
    this.toggleRelated = this.toggleRelated.bind(this);
    this.updateRelated = this.updateRelated.bind(this);
    this.getReferWord = this.getReferWord.bind(this);
  }

  componentDidMount() {
    if (document.getElementById("react-app"))
      document.getElementById("react-app").removeAttribute("class");
    this.props.setTlRef(this.props.userId, this.state.id, this.timelineNode);
    setTimeout(() => {
      document
        .querySelector(`#${this.state.id}`)
        .scrollTo(0, this.props.getScrollPos());
      this.setState({ loaded: true });
    }, 5);
  }

  componentWillUnmount() {
    this.props.setScrollPos(
      document.querySelector(`#${this.state.id}`).scrollTop
    );
  }

  toggleRelated(type: string, word: string) {
    if (!this.state.related) {
      this.props.postRelatedWord(type, word);
    } else {
      this.props.resetRelatedWord();
    }

    this.setState({
      related: !this.state.related,
    });
  }

  updateRelated(type: string, word: string) {
    this.props.postRelatedWord(type, word);
  }

  disableToolTip(e) {
    this.props.disableToolTip();
  }

  sendNote(text) {
    this.props.sendNote(
      this.props.userId,
      this.props.projectId,
      this.props.selectedPostId,
      this.props.selectedPresentedId,
      text
    );
  }

  updateNote(text) {
    const info = this.getInfo();
    const updatedText = info && !!info.memo ? info.memo + "\n\n" + text : text;
    this.props.sendNote(
      this.props.userId,
      this.props.projectId,
      this.props.selectedPostId,
      this.props.selectedPresentedId,
      updatedText
    );
  }

  getInfo() {
    if (this.props.selectedPostId && this.props.selectedPresentedId) {
      const filtered = this.props.posts.filter((group) => {
        return (
          group.postId === this.props.selectedPostId &&
          group.posts.filter(
            (post) => post.presentedId === this.props.selectedPresentedId
          ).length > 0
        );
      });

      return filtered[0].posts.filter(
        (post) => post.presentedId === this.props.selectedPresentedId
      )[0];
    } else {
      return null;
    }
  }

  getTlNoteInfo() {
    if (!this.state.tlMemoSpeakerId) return null;

    let post = [];
    const filtered = this.props.posts.filter((group) => {
      return (
        group.postId === this.state.tlMemoPostId &&
        group.posts.filter(
          (post) =>
            post.isDeleted !== undefined &&
            post.presentedId === this.state.tlMemoPresentedId
        ).length > 0
      );
    });

    if (filtered.length) {
      post = filtered[0].posts.filter(
        (post) =>
          post.presentedId === this.state.tlMemoPresentedId &&
          post.isDeleted !== undefined &&
          !post.isDeleted &&
          parseInt(post.speakerId) === this.state.tlMemoSpeakerId
      );
    } else {
      post = [];
    }

    if (post.length === 0) {
      return null;
    } else {
      return {
        ...post[0],
        word: "",
        memo: post[0].word,
      };
    }
  }

  getProjectNoteInfo() {
    return {
      postId: -1,
      speakerId: "-1",
      postTime: 0,
      memo: this.props.projectMemo
    };
  }

  sendQuietly() {
    let lastPost;
    for (let i = 0; i < this.props.posts.length; i++) {
      if ([...this.props.posts].reverse()[i].type === "me") {
        lastPost = [...this.props.posts].reverse()[i];
        break;
      }
    }

    const contents = lastPost.posts[0].word;
    const postId: number = [...this.props.posts].reverse()[0].posts[0].postId;

    this.props.sendQuietly(
      this.props.userId,
      this.props.projectId,
      postId,
      typeof contents === "string" ? contents : contents.join("&")
    );
    this.props.disableToolTip();
  }

  createTlMemo(
    postId: number,
    presentedId: number | null,
    speakerId: number | null
  ) {
    this.setState({
      tlMemoModal: true,
      tlMemoPostId: postId,
      tlMemoPresentedId: presentedId,
      tlMemoSpeakerId: speakerId,
    });
  }

  closeTlMemo() {
    this.setState({
      tlMemoModal: false,
      tlMemoPostId: null,
      tlMemoSpeakerId: null,
    });
  }

  createProjectMemo() {
    this.setState({
      projectMemoModal: true,
    });
  }

  closeProjectMemo() {
    this.setState({
      projectMemoModal: false,
    });
  }

  sendTlMemo(text: string) {
    this.props.sendTlMemo(
      this.state.tlMemoPostId,
      this.props.userId,
      this.props.projectId,
      text
    );
    this.setState({
      tlMemoModal: false,
      tlMemoPostId: null,
      tlMemoSpeakerId: null,
    });
  }

  sendProjectMemo(text: string) {
    this.props.sendProjectMemo(text);
    this.setState({
      projectMemoModal: false,
    });
  }

  updateTlMemo(text: string) {
    this.props.updateTlMemo(
      this.state.tlMemoPostId,
      this.state.tlMemoPresentedId,
      this.props.userId,
      this.props.projectId,
      this.state.tlMemoSpeakerId,
      text
    );
    this.setState({
      tlMemoModal: false,
      tlMemoPostId: null,
      tlMemoSpeakerId: null,
    });
  }

  removeTlMemo() {
    this.props.removeTlMemo(
      this.state.tlMemoPostId,
      this.state.tlMemoPresentedId,
      this.props.userId,
      this.props.projectId,
      this.state.tlMemoSpeakerId
    );
    this.setState({
      tlMemoModal: false,
      tlMemoPostId: null,
      tlMemoSpeakerId: null,
    });
  }

  removeProjectMemo() {
    this.props.sendProjectMemo("");
    this.setState({
      projectMemoModal: false,
    });
  }

  toggleDescription() {
    this.setState({ toggleDescription: !this.state.toggleDescription });
  }

  getReferWord(postId, presentedId) {
    const post = this.props.posts
      .map((group) => group.posts)
      .flat()
      .find(
        (post) => post.postId === postId && post.presentedId === presentedId
      );
    return post ? post.word : undefined;
  }

  render() {
    const your_dialog: Array<Group> = this.props.posts.filter(
      (group) => group.type === "you"
    );
    const last_your_dialog_id: number = your_dialog.length
      ? your_dialog.reverse()[0].postId
      : 0;

    return (
      <div
        className={cn("timeline-wrapper", { loaded: this.state.loaded })}
        id={this.state.id}
      >
        <Alert />
        {this.timelineNode && !this.props.isPc && (
          <Header
            tlMemo={this.props.tlMemo}
            toggleNote={() => {
              this.props.setTlMemo(!this.props.tlMemo);
            }}
            toggleMenu={this.props.toggleSourceMenu}
            filtered={this.props.filtered}
            toggleFilter={this.props.toggleFilter}
            shared={this.props.shared}
            tlId={this.state.id}
            projectSummary={this.props.info}
            labelClick={this.props.toggleMenu}
          />
        )}
        <section
          className={cn("contents", {
            filtered: this.props.filtered,
            shared: this.props.shared,
          })}
          id="contents"
          ref={(node) => {
            this.timelineNode = node;
          }}
        >
          {/* 吹き出しメモ */}
          <CSSTransition
            in={
              this.props.note &&
              !!this.props.selectedPostId &&
              !!this.props.selectedPresentedId
            }
            classNames="menu"
            unmountOnExit
            timeout={100}
          >
            <NoteInput
              toggle={this.props.toggleNote}
              send={(text) => {
                this.sendNote(text);
              }}
              info={this.getInfo()}
              remove={() => this.sendNote("")}
            />
          </CSSTransition>

          {/* PROJECTメモ */}
          <CSSTransition
            in={this.state.projectMemoModal}
            classNames="menu"
            unmountOnExit
            timeout={100}
          >
            <NoteInput
              toggle={this.closeProjectMemo}
              send={this.sendProjectMemo}
              update={this.sendProjectMemo}
              info={this.getProjectNoteInfo()}
              remove={this.removeProjectMemo}
            />
          </CSSTransition>

          {/* TLメモ */}
          <CSSTransition
            in={this.state.tlMemoModal}
            classNames="menu"
            unmountOnExit
            timeout={100}
          >
            <NoteInput
              toggle={this.closeTlMemo}
              send={(text) => {
                this.sendTlMemo(text);
              }}
              update={(text) => {
                this.updateTlMemo(text);
              }}
              info={this.getTlNoteInfo()}
              remove={this.removeTlMemo}
            />
          </CSSTransition>

          {/* 関連語 */}
          <CSSTransition
            in={this.state.related}
            classNames="menu"
            unmountOnExit
            timeout={100}
          >
            <RelatedWords
              {...this.props.relatedWords}
              toggleMenu={this.toggleRelated}
              sendWord={(input, sourceId) => {
                this.props.disableToolTip();
                this.props.send(
                  this.props.userId,
                  this.props.projectId,
                  this.props.selectedPostId,
                  this.props.selectedPresentedId,
                  input,
                  sourceId
                );
              }}
              sendNote={(text) => {
                this.updateNote(text);
              }}
              toggleRelated={this.updateRelated}
            />
          </CSSTransition>

          {/* ツールチップ */}
          <CSSTransition
            in={this.props.toolTip}
            classNames="tooltip"
            timeout={100}
            unmountOnExit
          >
            <ToolTip disableToolTip={this.disableToolTip} />
          </CSSTransition>

          {!this.props.isPc && (
            <div className={d("header")} onClick={this.props.toggleMenu}>
              {this.props.info ? this.props.info.name : ""}
              <FA
                name="angle-down"
                style={{
                  marginLeft: "7px",
                  transform: "translateY(-4px)",
                  fontSize: "17px",
                  verticalAlign: "middle",
                  color: "#9f9ba1",
                }}
              />
            </div>
          )}

          {!this.props.isPc && (
            <div
              className={d("description", {
                toggle: this.state.toggleDescription,
              })}
              onClick={this.toggleDescription}
            >
              <div className={d("description-inner")}>
                <Linkify>
                  {this.props.info
                    ? this.props.info.description
                        .split("\n")
                        .map((text, index) => <p key={index}>{text}</p>)
                    : ""}
                </Linkify>
              </div>
              <div className={d("description-arrow")}>
                <FA
                  name="angle-down"
                  style={{ fontSize: "17px", color: "#9f9ba1" }}
                />
              </div>
            </div>
          )}

          <User {...this.props.getUser()} />
          {this.props.tlMemo && !this.props.shared && this.props.projectMemo === '' ?
            (
            <div className={d("note")}>
              <div className={d("note-inner")}>
                <button
                  className={d("note-btn")}
                  onClick={() => {
                    this.createProjectMemo();
                  }}
                >
                  <Note />
                </button>
              </div>
            </div>
          ):null}

          {!this.props.shared && this.props.projectMemo !== ''?
            <TimelineNote
              postTime={0}
              postId={-1}
              speakerId="-1"
              word={this.props.projectMemo}
              name={this.props.usersInfo[this.props.userId][0]}
              url={this.props.usersInfo[this.props.userId][1]}
              edit={()=>{this.createProjectMemo()}}
            />
          :null}

          {this.props.posts &&
            this.props.posts.map((group: Group, key: number) => {
              const isLast = group.postId === last_your_dialog_id;
              const lastGroup = key + 1 === this.props.posts.length;
              const next =
                this.props.posts.length < key + 1
                  ? null
                  : this.props.posts[key + 1];
              return (
                <Fragment key={key}>
                  <DialogGroup
                    {...group}
                    getReferWord={(postId, presentedId) =>
                      this.getReferWord(postId, presentedId)
                    }
                    lastId={last_your_dialog_id}
                    note={this.props.note}
                    selectedPostId={this.props.selectedPostId}
                    selectedPresentedId={this.props.selectedPresentedId}
                    queue={this.props.queue}
                    shared={this.props.shared}
                    projectId={this.props.projectId}
                    projectHash={this.props.projectHash}
                    userId={this.props.userId}
                    sendQuietly={this.sendQuietly}
                    updateTlMemo={(
                      postId,
                      presentedId,
                      tlUserId,
                      speakerId,
                      memo
                    ) => {
                      if (speakerId === this.props.loginUserInfo.id)
                        this.createTlMemo(postId, presentedId, speakerId);
                      console.log(postId, tlUserId, memo);
                    }}
                    toggleRelated={this.toggleRelated}
                    slide={this.props.slide}
                  />

                  {this.props.tlMemo &&
                    group.type !== "queue" &&
                    (!next || (next && next.type !== "you")) && (
                      <div className={d("note")}>
                        <div className={d("note-inner")}>
                          <button
                            className={d("note-btn")}
                            onClick={() => {
                              this.createTlMemo(group.postId, null, null);
                            }}
                          >
                            <Note />
                          </button>
                        </div>
                      </div>
                    )}

                  {isLast &&
                    lastGroup &&
                    this.props.queue.length === 0 &&
                    !this.props.shared && <More onClick={this.sendQuietly} />}
                </Fragment>
              );
            })}

          {this.props.thinking && (
            <section className="thinking">
              <div className="thinking__inner">
                <div className="dot-flashing"></div>
              </div>
            </section>
          )}
        </section>

        {!this.props.shared && this.props.queue && (
          <TextInput
            send={(input) => {
              this.props.send(
                this.props.userId,
                this.props.projectId,
                null,
                null,
                input
              );
            }}
            queue={() => {
              this.props.sendOnlyQueue(this.props.userId, this.props.projectId);
            }}
            isQueueExist={this.props.queue.length > 0}
          />
        )}
      </div>
    );
  }
}

export default TimeLineComponent;
