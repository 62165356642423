import React, { Component } from 'react';
import {setup} from 'bem-cn'
import './radio.scss'
import { checkFile } from '../../../utils/fileManager';

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const b = block('radio')

interface Props {
  checked: boolean,
  check(): void
}
interface State {
  id: string,
}

class Radio extends Component<Props, State> {
  public state: State = {
    id: Math.random().toString(36).slice(2),
  }

  check(e){
  }

  render() {
    return (
      <span className={b()}>
        <input type="radio" name={`radio-${this.state.id}`} checked={this.props.checked} id={`radio-${this.state.id}`} onChange={this.props.check} />
        <label htmlFor={`radio-${this.state.id}`}>{this.props.children}</label>
      </span>
    );
  }
}

export default Radio;
