import createAsyncActions from '../../../utils/createAsyncActions';

const PREFIX = 'AUTH';
const SEPARATOR = '_';

//sync actions
const POST = PREFIX + SEPARATOR + 'POST';
const LOGOUT = PREFIX + SEPARATOR + 'LOGOUT';
const SET_TOKEN = PREFIX + SEPARATOR + 'SET_TOKEN';

//async actions
const post = createAsyncActions(POST);

export { LOGOUT, SET_TOKEN, post };
