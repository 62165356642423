import React, { Component } from 'react';
import Menu from '../common/menu';
import CheckBox from '../projects/checkbox';

interface Props {
  toggleMenu: any,
  sources: any[],
  projectId: number,
  toggleSource(name:string): void,
  togglePartOfSpeech(name:string): void,
  partOfSpeech: any[]
}
interface State {
}

class OtherMenu extends Component<Props, State> {
  public state: State = {
  }

  componentDidMount(){
  }

  render() {
    return (
      <Menu onClose={this.props.toggleMenu} onDone={this.props.toggleMenu} done="" slide>
        <h2 className="menu__heading">参照元ソース</h2>
        <section className="menu__section">
          {this.props.sources.map((source,key)=>(
            <CheckBox
              {...source}
              key={key}
              toggleSource={()=>this.props.toggleSource(source.name)}
            >{source.display}</CheckBox>
          ))}
        </section>
        <h2 className="menu__heading">品詞</h2>
        <section className="menu__section">
          {this.props.partOfSpeech.map((source,key)=>(
            <CheckBox
              {...source}
              key={key}
              toggleSource={()=>this.props.togglePartOfSpeech(source.name)}
            >{source.display}</CheckBox>
          ))}
        </section>
      </Menu>
    );
  }
}

export default OtherMenu;
