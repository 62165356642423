import loadImage from 'blueimp-load-image';

const checkFile = e => {
  const CANT_USE_MULTIPLE_FILES = "複数ファイルはアップロードできません";
  //const FILE_NOT_FOUND = "ファイルが見つかりません";
  const MAX_UPLOAD_SIZE = 5*1024*1024;
  const ONLY_IMAGE_CAN_UPLOAD   = "画像ファイル以外はアップロードできません";
  const UPLOAD_LIMIT = `アップロードできる画像ファイルのサイズ上限は${MAX_UPLOAD_SIZE/1024/1024}MBです。`;

  let status = {
    result: false,
    message: ''
  }

  let files;

  if (typeof e.dataTransfer === 'undefined') {
    files = e.target.files;
  } else {
    files = e.dataTransfer.files;
  }

  if (files.length > 1) {
    status = {
      result: false,
      message: CANT_USE_MULTIPLE_FILES
    }
    return status;
  } else if (files.length === 0) {
    return status;
  }

  const file = files[0];
  const fileType = file.type;

  if(file.size > MAX_UPLOAD_SIZE){
    status = {
      result: false,
      message: UPLOAD_LIMIT
    }
    return status;
  }

  if (!fileType.match(/image.*/)) {
    status = {
      result: false,
      message: ONLY_IMAGE_CAN_UPLOAD
    }
    return status;
  }
  status = {
    result: true,
    message: ''
  }
  return status;
};

const getFile = async e => new Promise((resolve,reject)=>{
  const status = checkFile(e);
  if(!status.result) reject(status);

  let files;

  if (typeof e.dataTransfer === 'undefined') {
    files = e.target.files;
  } else {
    files = e.dataTransfer.files;
  }

  loadImage.parseMetaData(files[0], data=>{
    let options:any = {canvas: true}
    if(data.exif)options.orientation = data.exif.get('Orientation')
    loadImage(files[0], canvas=>{
      resolve({
        ...status,
        file: files[0],
        url: canvas.toDataURL('image/jpeg')
      })
    }, options)
  })
})

export  {checkFile, getFile};
