import React, { Component } from 'react';
import {setup} from 'bem-cn';
import moment from 'moment'
import 'moment/locale/ja'
import './user.scss'
import UserIcon from '../common/user-icon'
import UserType from '../../../types/user'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});
const b = block('user')

interface State {
}

class User extends Component<UserType, State> {
  public state: State = {
  }

  constructor(props:UserType){
    super(props);
    this.getLastUpdate = this.getLastUpdate.bind(this)
  }

  getLastUpdate(){
    if(this.props.lastUpdate === 0) return '未投稿'
    return moment(this.props.lastUpdate).fromNow()
  }

  render() {
    const style = {
      margin: `0 7px 0 0`
    }
    return (
        <div className={b()}>
          <UserIcon image={this.props.iconUrl} style={style} size={40}/>

          <div className={b('info')}>
            <h2 className={b('user-name')}>{this.props.name}</h2>
            <span className={b('last-update')}>最終更新 {this.getLastUpdate()}</span>
          </div>
        </div>
    );
  }
}
export default User;
