import React, { Component } from 'react';

interface Props {
}
interface State {}

class NotFound extends Component<Props, State> {
  public state: State = {
  }

  componentDidMount() {
    document.getElementById('react-app').removeAttribute('class');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="wrapper">
        not found
      </div>
    );
  }
}

export default NotFound;
