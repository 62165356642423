import React, { Component } from 'react';
import cn from 'classnames';
import {setup} from 'bem-cn';

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});
const b = block('menu')

interface Props {
  title?: string;
  onClose(e?: any): void;
  onDone?(e?: any): void;
  slide?: boolean;
  disabled?: boolean;
  done: string;
  remove?(): void;
  titleAlign?: "left" | "center" | "right";
}
interface State {}

class Menu extends Component<Props, State> {
  public state: State = {
  }

  done(){
    if(this.props.disabled === true)return;
    this.props.onDone();
  }

  render() {
    return (
      <div className={cn("menu", { "menu--slide": this.props.slide })}>
        <div className={b("header")}>
          <div className={b("header-left")} onClick={this.props.onClose}>
            <img src="/images/close.svg" alt="" />
          </div>
          <div className={b("header-center").mix( this.props.titleAlign )}>
            <h1>{this.props.title}</h1>
          </div>
          <div className={b("header-right")}>
            {this.props.onDone && (
              <button
                onClick={this.done.bind(this)}
                className={b("btn", { disabled: this.props.disabled })}
              >
                {this.props.done}
              </button>
            )}
          </div>
        </div>
        <div className={b("content")}>{this.props.children}</div>
        {this.props.remove && (
          <div className={b("footer")}>
            <button onClick={this.props.remove}>
              <img src="/images/trash.svg" alt="" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default Menu;
