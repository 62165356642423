import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import util from "./ducks/util/reducers";
import auth from './ducks/auth/reducers';
import login from './ducks/login/reducers';
import project from './ducks/project/reducers';
import projects from './ducks/projects/reducers';

import utilState from "./ducks/util/state";
import authState from './ducks/auth/state';
import loginState from './ducks/login/state';
import projectState from './ducks/project/state';
import projectsState from './ducks/projects/state';

const loggerMiddleware = createLogger({
  predicate: (getState, action) => process.env.NODE_ENV !== 'production'
});

/**
 * Reducerコンパイル
 */
const reducers = combineReducers({
  util,
  auth,
  login,
  projects,
  project,
});

/**
 * Store設定、Middlware設定
 * @param {Object} initialState
 */
const configureStore = initialState => {
  const enhancer = compose(applyMiddleware(ReduxThunk, loggerMiddleware));
  return createStore(reducers, initialState, enhancer);
};

export default configureStore({
  util: utilState,
  auth: authState,
  login: loginState,
  projects: projectsState,
  project: projectState,
});
