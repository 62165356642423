const sourcesFromLS = JSON.parse(window.localStorage.getItem('sources'));
const partOfSpeechFromLS = JSON.parse(window.localStorage.getItem('partOfSpeech'));
let partOfSpeech = [
      {
        name: '名詞',
        display: '名詞',
        valid: true
      },
      {
        name: '動詞',
        display: '動詞',
        valid: false
      },
      {
        name: '形容詞',
        display: '形容詞',
        valid: false
      },
    ]

if(partOfSpeechFromLS) {
  partOfSpeech = [
    partOfSpeechFromLS.find(s=>s.name === '名詞'),
    partOfSpeechFromLS.find(s=>s.name === '動詞'),
    partOfSpeechFromLS.find(s=>s.name === '形容詞'),
  ]
}

const sources = sourcesFromLS
  ? sourcesFromLS
  : [
      {
        name: 'amazon',
        display: 'Amazon',
        valid: false
      },
      {
        name: 'aozora',
        display: '青空文庫',
        valid: false
      },
      {
        name: 'copywriting',
        display: 'Copywriting',
        valid: false
      },
      {
        name: 'jlyric',
        display: 'J-lyric',
        valid: false
      },
      {
        name: 'wikipedia',
        display: 'Wikipedia',
        valid: true
      },
      {
        name: 'wired',
        display: 'WIRED',
        valid: false
      }
    ];

export default {
  projectSummary: null,
  sources: sources,
  partOfSpeech: partOfSpeech,
  timelines: [],
  createdUserId: null,
  description: null,
  loginUserId: null,
  projectId: null,
  projectMemo: "",
  usersInfo: [],
  relatedWords: {
    title: "",
    type: "dictionary",
    words: [],
  },
  joined: false,
};
