import { connect } from 'react-redux';
import Project from '../pages/project';
import MultiTimeline from "../pages/multiTimeline";
import * as Actions from '../../states/ducks/project/actions';
import woothee from "woothee";

const ua = woothee.parse(window.navigator.userAgent);
const Component = ua.category === "pc" ? MultiTimeline : Project;

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    ...state.project,
    loginUserInfo: state.projects.user,
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    init: (projectHash: string, userId: number) => {
      dispatch(Actions.get_project({ projectHash, userId }));
      dispatch(Actions.get_project_info({ projectHash, userId }));
    },
    reset: () => dispatch(Actions.reset()),
    getScrollPos: (projectHash: string, userId: number): number => {
      const scrollPos = JSON.parse(window.localStorage.getItem("scrollPos"));
      if (
        scrollPos &&
        scrollPos.find(
          (pos) => pos.projectHash === projectHash && pos.userId === userId
        )
      ) {
        return scrollPos.find(
          (pos) => pos.projectHash === projectHash && pos.userId === userId
        ).posY;
      } else {
        return 0;
      }
    },
    setScrollPos: (projectHash: string, userId: number, posY: number) => {
      let scrollPos = JSON.parse(window.localStorage.getItem("scrollPos"));
      if (
        scrollPos &&
        scrollPos.find(
          (pos) => pos.projectHash === projectHash && pos.userId === userId
        )
      ) {
        scrollPos = scrollPos.map((sPos) => {
          if (sPos.projectHash === projectHash && sPos.userId === userId) {
            return {
              projectHash,
              userId,
              posY,
            };
          }
          return sPos;
        });
      } else if (scrollPos) {
        scrollPos.push({
          projectHash,
          userId,
          posY,
        });
      } else {
        scrollPos = [];
        scrollPos.push({
          projectHash,
          userId,
          posY,
        });
      }
      window.localStorage.setItem("scrollPos", JSON.stringify(scrollPos));
    },
    togglePartOfSpeech: (name) => dispatch(Actions.toggle_part_of_speech(name)),
    toggleSource: (name) => dispatch(Actions.toggle_source(name)),
  };
};

const ProjectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default ProjectContainer;
