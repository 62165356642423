import { connect } from 'react-redux';
import NotFound from '../pages/not-found';

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return state.project;
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {};
};

const NotFoundContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFound);

export default NotFoundContainer;
