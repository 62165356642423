import req from '../../../utils/req';
import Cookies from 'js-cookie';

import * as types from './types';

const create = payload => async (dispatch, getState) => {
  dispatch(types.create.pending());
  const res = await req('POST_PROJECT', { data: payload }, Cookies.get('token')).catch(e => {
    dispatch(types.create.rejected(e));
    return;
  });

  const projectsRes = await req('GET_PROJECTS', {}, Cookies.get('token')).catch(e => {
    dispatch(types.create.rejected(e));
    return;
  });

  if (projectsRes) {
    dispatch(types.create.fulfilled());
    dispatch(types.get.fulfilled(projectsRes.data));
  }
};

const update = payload => async (dispatch, getState) => {
  dispatch(types.create.pending());
  const res = await req('PUT_PROJECT', { data: payload }, Cookies.get('token')).catch(e => {
    dispatch(types.create.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.update.fulfilled(payload));
  }
};

const remove = payload => async (dispatch, getState) => {
  dispatch(types.remove.pending());
  const res = await req(
    'PUT_PROJECT',
    { data: { projectId: payload, isDeleted: '1' } },
    Cookies.get('token')
  ).catch(e => {
    dispatch(types.remove.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.remove.fulfilled(payload));
  }
};

const remove_shared = payload => async (dispatch, getState) => {
  dispatch(types.remove_shared.pending());
  const res = await req(
    'PUT_SHARED_PROJECT',
    { data: { projectHash: payload, isDeleted: '1' } },
    Cookies.get('token')
  ).catch(e => {
    dispatch(types.remove_shared.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.remove_shared.fulfilled(payload));
  }
};

const get = payload => async (dispatch, getState) => {
  if (!Cookies.get('token')) return;
  dispatch(types.get.pending());
  const res = await req('GET_PROJECTS', {}, Cookies.get('token')).catch(e => {
    dispatch(types.get.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.get.fulfilled(res.data));
  }
};

const getUser = () => async (dispatch, getState) => {
  if (!Cookies.get('token')) return;
  dispatch(types.get_user.pending());
  const res = await req('GET_ACCOUNT', {}, Cookies.get('token')).catch(e => {
    dispatch(types.get_user.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.get_user.fulfilled(res.data));
  }
};

const putUser = payload => async (dispatch, getState) => {
  if (!Cookies.get('token')) return;
  dispatch(types.put_user.pending());
  const res = await req('PUT_ACCOUNT', { data: payload }, Cookies.get('token'), true).catch(e => {
    dispatch(types.put_user.rejected(e));
    return;
  });

  if (res) {
    dispatch(types.put_user.fulfilled(res.data));
  }
};

export { get, getUser, putUser, create, update, remove, remove_shared };
