import React, { Fragment, Component } from 'react';
import './divider.scss';
import {setup} from 'bem-cn';
import UserIcon from '../common/user-icon'
import User from '../../../types/user'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const b = block('divider')

interface Props {
  type: number,
  user?: User,
  post?: string,
  jump():void
}

interface State {
}

class Divider extends Component<Props, State> {
  public state: State = {
  }

  getContent(){
    const style={
      verticalAlign: 'middle',
      margin: '0 10px 0 0',
      display: 'inline-block'
    }

    switch(this.props.type) {
      case 1:
        return <span className={b('inner')}>{`「${this.props.post}」の同義語`}</span>
      case 2:
        return <span className={b('inner')}>{`「${this.props.post}」の対義語`}</span>
      case 3:
        return <span className={b('inner')}>{`「${this.props.post}」の関連語`}</span>
      case 4:
        return <span className={b('inner')}>{`「${this.props.post}」の組合せ`}</span>
      case 5:
        return <span className={b('inner')}>過去のワード</span>
      case 6:
        return this.props.user ?(
          <span className={b('inner')} onClick={this.props.jump}>
            <UserIcon size={40} image={this.props.user.iconUrl} style={style}/>
            <span className={b('type')}>{this.props.user.name}さんのワード</span>
            <span className={b('arrow')} style={{backgroundImage: "url(/images/arrow-right.svg)"}}/>
          </span>
        ): null
      default :
        return null
    }
  }

  render() {
    return (
      <div className={b()}>
        <span/>
        {this.getContent()}
        <span/>
      </div>
    );
  }
}

export default Divider;
