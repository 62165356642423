import createAsyncActions from "../../../utils/createAsyncActions";

const PREFIX = "UTIL";
const SEPARATOR = "_";

//sync actions
const GET_USERS = PREFIX + SEPARATOR + "GET_USERS";

//async actions
const get_users = createAsyncActions(GET_USERS);

export { get_users };
