import React, { Component, Fragment} from 'react';
import { connect } from 'react-redux';
import cn from 'classnames'
import Post from '../../../types/post'
import Chat from './chat';
import * as Actions from '../../../states/ducks/project/actions';
import {setup} from 'bem-cn'
import Group from '../../../types/group'
import User from '../../../types/user'
import TimelineNote from './timeline-note';
import {date} from '../../../utils/utils'
import Divider from '../project/divider'

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const d = block('dialog')

//どのstateをpropsでrecieveするか
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    usersInfo: state.project.usersInfo,
    postedUserIds: state.project.postedUserIds
  };
};

//どのactionCreatorをpropsでrecieveするか
const mapDispatchToProps = dispatch => {
  return {
    enableToolTip: (userId: number, postId: number, presentedId: number) =>
      dispatch(Actions.enable_tooltip({ userId, postId, presentedId })),
    disableToolTip: () => dispatch(Actions.disable_tooltip()),
    remove: (projectId: number, postId: number, presentedId: number) =>
      dispatch(Actions.remove_post({ projectId, postId, presentedId })),
    copy: (userId, projectId, postId, presentedId, projectHash, text) =>
      dispatch(
        Actions.copy({
          userId,
          projectId,
          postId,
          presentedId,
          text,
          projectHash,
          isQueued: false,
        })
      ),
    send: (userId, projectId, postId, presentedId, text, isLast) => {
      let data: any = {
        userId,
        projectId,
        postId,
        presentedId,
        text,
        isQueued: false,
      };
      if (!isLast) data.sourceId = 5;
      dispatch(Actions.send(data));
    },
    toggleNote: (toggle?: boolean) => dispatch(Actions.toggle_note(toggle)),
    togglePin: (userId, projectId, postId, presentedId, isPin) =>
      dispatch(
        Actions.toggle_pin({
          userId,
          projectId,
          postId,
          presentedId,
          isPin: !isPin ? "1" : "0",
        })
      ),
    sendQueue: (userId, projectId, postId, presentedId, text) =>
      dispatch(
        Actions.sendQueue({
          userId,
          projectId,
          postId,
          presentedId,
          text,
        })
      ),
  };
};

interface Props extends Group {
  note: boolean;
  shared: boolean;
  lastId: number;
  queue: Array<any>;
  enableToolTip(userId: number, postId: number, presentedId: number): void;
  disableToolTip(): void;
  remove(projectId: number, postId: number, presentedId: number): void;
  copy(
    userId: number,
    projectId: number,
    postId: number | null,
    presentedId: number | null,
    projectHash: string,
    text: string
  ): void;
  send(
    userId: number,
    projectId: number,
    postId: number | null,
    presentedId: number | null,
    text: string,
    isLast: boolean
  ): void;
  toggleNote(toggle?: boolean): void;
  togglePin(
    userId: number,
    projectId: number,
    postId: number,
    presentedId: number,
    isPin: boolean
  ): any;
  projectId: number;
  projectHash: string;
  userId: number;
  sendQueue(
    userId: number,
    projectId: number,
    postId: number,
    presentedId: number,
    text: string
  ): any;
  sendQuietly(): void;
  selectedPostId: number | null;
  selectedPresentedId: number | null;
  usersInfo: any[];
  postedUserIds: number[];
  updateTlMemo(
    postId: number,
    presentedId: number,
    tlUserId: number,
    projectId: number,
    memo: string
  );
  toggleRelated(type: string, word: string): void;
  slide(index: number, postId: number, presentedId: number): void;
  getReferWord(postId: number, presentedId: number): string;
}

interface State {
}

class DialogGroupComponent extends Component<Props, State> {
  public state: State = {
  }

  constructor(props:Props){
    super(props);
    this.note = this.note.bind(this)
    this.enableToolTip = this.enableToolTip.bind(this)
    this.send = this.send.bind(this)
    this.getUserInfo = this.getUserInfo.bind(this)
  }

  send(post:Post, text:string, isLast:boolean){
    if (this.props.shared) {
      this.props.copy(
        this.props.userId,
        this.props.projectId,
        post.postId,
        post.presentedId,
        this.props.projectHash,
        text
      );
    } else {
      this.props.send(
        this.props.userId,
        this.props.projectId,
        post.postId,
        post.presentedId,
        text,
        isLast
      );
    }
  }

  enableToolTip(obj){
    this.props.enableToolTip(this.props.userId, obj.postId, obj.presentedId)
  }

  getType(){
    return this.props.type;
  }

  note(){
    this.props.toggleNote(true)
  }

  sendQueue(postId, presentedId, text){
    this.props.sendQueue(this.props.userId, this.props.projectId, postId, presentedId, text);
  }

  getUserInfo(userId:number){
    if(Object.keys(this.props.usersInfo).length > 0 && this.props.usersInfo[userId]) {
      const userInfo = this.props.usersInfo[userId]
      return {
        name: userInfo[0],
        image: userInfo[1]
      }
    }else{
      return {name: '',image: ''}
    }
  }

  jump(referrerUser:any){
    const index = this.props.postedUserIds.indexOf(referrerUser.referrerUserId)
    this.props.slide(index, referrerUser.referrerPostId, referrerUser.referrerPresentedId)
  }

  render() {
    const isLast = this.props.postId === this.props.lastId
    if (this.props.posts[0].isDeleted) return null;
    return (
      <section className={cn(`dialog`, this.getType(), {'dialog--last': isLast})} id={`post_${this.props.postId}`}>
        {this.props.posts.map((post:Post,key) => {
          if (post.isDeleted !== undefined && !post.isDeleted) {
            let info;

            if (
              this.props.usersInfo === undefined ||
              post.speakerId === "0" ||
              this.props.usersInfo[post.speakerId] === undefined
            ) {
              info = { name: "", url: "" };
            } else {
              info = {
                name: this.props.usersInfo[post.speakerId][0],
                url: this.props.usersInfo[post.speakerId][1],
              };
            }
            return (
              <TimelineNote
                key={key}
                {...post}
                {...info}
                edit={() => {
                  this.props.updateTlMemo(
                    post.postId,
                    post.presentedId,
                    this.props.projectId,
                    parseInt(post.speakerId),
                    post.contents
                  );
                }}
              />
            );
          } else {
            let user: User;
            let referrer_post: string;

            if (
              this.props.referrerUser &&
              this.props.referrerUser.referrerUserId &&
              this.props.usersInfo
            ) {
              user = {
                name: this.props.usersInfo[
                  `${this.props.referrerUser.referrerUserId}`
                ][0],
                iconUrl: this.props.usersInfo[
                  `${this.props.referrerUser.referrerUserId}`
                ][1],
              };
            }

            if (
              this.props.referrerUser &&
              this.props.referrerUser.referrerPostId
            ) {
              referrer_post = this.props.getReferWord(
                this.props.referrerUser.referrerPostId,
                this.props.referrerUser.referrerPresentedId
              );
            }

            return (
              <Fragment key={key}>
                {this.props.sourceId && (
                  <Divider
                    type={this.props.sourceId}
                    user={user}
                    post={referrer_post}
                    jump={() => {
                      this.jump(this.props.referrerUser);
                    }}
                  />
                )}

                {this.props.type === "me" ? (
                  <span className="date">{date(`${post.postTime}`)}</span>
                ) : null}

                <Chat
                  {...post}
                  type={this.props.type}
                  send={(text) => {
                    this.send(post, text, isLast);
                  }}
                  sendQueue={(text) => {
                    this.sendQueue(post.postId, post.presentedId, text);
                  }}
                  enableToolTip={this.enableToolTip}
                  floated={
                    this.props.selectedPostId &&
                    this.props.selectedPresentedId &&
                    this.props.selectedPostId === post.postId &&
                    this.props.selectedPresentedId === post.presentedId
                  }
                  togglePin={() => {
                    this.props.togglePin(
                      this.props.userId,
                      this.props.projectId,
                      post.postId,
                      post.presentedId,
                      post.isPin
                    );
                  }}
                  note={this.note}
                  remove={() => {
                    this.props.remove(
                      this.props.projectId,
                      post.postId,
                      post.presentedId
                    );
                    this.props.disableToolTip();
                  }}
                  shared={this.props.shared}
                  noteAppear={this.props.note}
                  toggleRelated={this.props.toggleRelated}
                  getUserInfo={this.getUserInfo}
                  jump={(postId, userId) =>
                    this.jump({
                      referrerPostId: postId,
                      referrerUserId: userId,
                      referrerPresentedId: 1,
                    })
                  }
                />
              </Fragment>
            );
          }
        }
        )}
      </section>
    );
  }
}

const DialogGroup = connect(
  mapStateToProps,
  mapDispatchToProps
)(DialogGroupComponent);

export default DialogGroup;
