import React, { Component } from 'react';
import Menu from '../common/menu';
import TextareaAutosize from 'react-textarea-autosize';
import Post from '../../../types/post'

interface Props {
  toggle(): void,
  send(text): void,
  update?(text): void,
  remove?(): void,
  info: Post|null
}
interface State {
  text: string,
  put: boolean
}

class Note extends Component<Props, State> {
  public state: State = {
    text: this.props.info?this.props.info.memo:'',
    put: false
  }

  constructor(props:Props){
    super(props)
  }

  componentDidMount(){
    this.setState({
      put: this.props.info !== null && this.props.info.memo !== undefined
    })
  }

  change(e){
    this.setState({
      text: e.target.value
    })
  }

  send(){
    if(this.state.put && this.props.update){
      this.props.update(this.state.text);
    }else{
      this.props.send(this.state.text);
    }
  }

  render() {
    const info = this.props.info;
    const word = info?info.word:'';

    return (
      <Menu onClose={this.props.toggle} onDone={this.send.bind(this)} done="DONE" remove={this.state.put?this.props.remove:undefined}>
        <div className="note">
          {word &&
            <h2 className="note__title"><span>{word}</span>についてのメモ</h2>
          }
          <section className="note__editable">
            <TextareaAutosize placeholder="Aa" minRows={5} maxRows={20} value={this.state.text} onChange={this.change.bind(this)} />
          </section>
        </div>
      </Menu>
    );
  }
}

export default Note;
