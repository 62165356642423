import React, { Component } from 'react';
import FA from 'react-fontawesome';

interface Props {
  onClick():void
}

const More = (props: Props) => {
  return (
    <div className="dialog__more">
      <span onClick={props.onClick}>
        MORE<FA name="angle-down" style={{ marginLeft: "5px" }} />
      </span>
    </div>
  );
}

export default More;
