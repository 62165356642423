import { createAction } from "redux-actions";
import Cookies from "js-cookie";
import req from "../../../utils/req";

import * as types from "./types";

//ユーザー一覧を取得する
const get_users = (payload) => async (dispatch, getState) => {
  dispatch(types.get_users.pending());

  const res = await req(
    "GET_USERS",
    { data: {}, params: {} },
    Cookies.get("token")
  ).catch((e) => {
    dispatch(types.get_users.rejected(e));
  });

  if (res) dispatch(types.get_users.fulfilled(res.data.users));
};

export { get_users };
