import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import User from '../../types/user'

interface Props {
  checkStatus: any,
  isLogin: number,
  needSSO: boolean,
  children: any,
  user: User|null
}
interface State {
  pathname: string
}

class Auth extends Component<Props, State> {
  public state: State = {
    pathname: this.props.needSSO?'/sso':'/login'
  }

  componentDidMount() {
    if(this.props.isLogin === 0)this.props.checkStatus();
  }

  render() {
    switch(this.props.isLogin){
      case 0:
        return null;
      case 1:
        return <Redirect to={{pathname: this.state.pathname}}/>;
      case 2:
        if(!this.props.user) return null;
        return this.props.children;
    }
  }
}

export default Auth;
