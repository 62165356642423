import React, { Component } from 'react';
import './timeline-note.scss';
import {setup} from 'bem-cn';
import UserIcon from '../common/user-icon'
import Linkify from 'react-linkify'
import Post from '../../../types/post'
import {date} from '../../../utils/utils'
import Hammer from 'react-hammerjs';

const block = setup({
    el: '__',
    mod: '--',
    modValue: '-'
});

const b = block('timeline-note')

interface Props extends Post{
  name: string,
  url: string,
  edit(): void
}

interface State {
}

class TimelineNote extends Component<Props, State> {
  public state: State = {
  }

  constructor(props:Props){
    super(props);
  }

  getWord(){
    if(typeof this.props.word !== 'string') return this.props.word
    return this.props.word.split('\n').map((line,index)=><span key={index}>{line}</span>)
  }

  render() {
    const options:object = {
      recognizers: {
        press: {
          time: 200
        }
      }
    }

    return (
      <div className={b()}>
        <div className={b("info")}>
          <UserIcon size={50} image={this.props.url} />
          <span className={b("name")}>{this.props.name}</span>
          <span className={b("date")}>
            {this.props.postTime > 0 && date(`${this.props.postTime}`)}
          </span>
        </div>

        <Hammer options={options} onPress={this.props.edit}>
          <p className={b("inner")}>
            <Linkify>{this.getWord()}</Linkify>
          </p>
        </Hammer>
      </div>
    );
  }
}

export default TimelineNote;
